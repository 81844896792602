import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayersService } from './services/integration.service';

@NgModule({
  imports: [CommonModule],
  providers: [LayersService],
  declarations: [],
})
export class IntegrationModule {}
