import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { validateStringMath } from 'src/app/core/validators/string-match.validator';
import { ExperienceService } from 'src/app/experience/services/experience/experience.service';
import { Experience } from 'src/generated/graphql';

@Component({
  selector: 'app-shared-modal-delete-experience',
  templateUrl: './shared-modal-delete-experience.component.html',
  styleUrls: ['./shared-modal-delete-experience.component.scss'],
})
export class SharedModalDeleteExperienceComponent implements OnInit {
  @Input() id: string;
  @Input() experience: Experience;
  formGroup: FormGroup;

  constructor(
    public translations: TranslationsLayoutService,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private experienceService: ExperienceService
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group(
      {
        experienceName: [
          { value: '', disabled: false },
          Validators.compose([Validators.required]),
        ],
      },
      {
        validator: validateStringMath({
          controlName: 'experienceName',
          matchString: this.experience.title,
        }),
      }
    );
  }

  async deleteExperience() {
    await this.experienceService.removeExperience(this.experience.id);

    this.closeModal({ deleted: true });
  }

  closeModal(data: any = undefined) {
    this.modalController.dismiss(data, undefined, this.id);
  }
}
