import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';

@Component({
  selector: 'shared-form-footer',
  templateUrl: './shared-form-footer.component.html',
  styleUrls: ['./shared-form-footer.component.scss'],
})
export class SharedFormFooterComponent implements OnInit {
  @Input() parentFormGroup: FormGroup | undefined;
  @Output() saveEvent = new EventEmitter<void>();

  constructor(public translations: TranslationsLayoutService) {}

  ngOnInit() {}

  save() {
    this.saveEvent.emit();
  }
}
