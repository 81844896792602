import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { NotificationPopOverComponent } from 'src/app/notification/components/notification-pop-over/notification-pop-over.component';
import { NotificationService } from 'src/app/notification/services/notification/notification.service';
import { UserPopOverProfileComponent } from 'src/app/user/components/user-pop-over-profile/user-pop-over-profile.component';
import { UserService } from 'src/app/user/services/user/user.service';
import {
  FilterOperation,
  NotificationType,
  RaioXQuery,
  UserRole,
} from 'src/generated/graphql';
import { ExperienceService } from '../../../experience/services/experience/experience.service';
import { SharedModalRememberRatingComponent } from '../../../shared/components/modals/shared-modal-remember-rating/shared-modal-remember-rating.component';
import { LayoutService } from '../../services/layout/layout.service';
import { TranslationsLayoutService } from '../../services/translations/translations-layout.service';

@Component({
  selector: 'core-header',
  templateUrl: './core-header.component.html',
  styleUrls: ['./core-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CoreHeaderComponent implements OnInit, OnDestroy {
  userRole = UserRole;
  isOpenSideMenu: boolean = false;
  sideMenuSubject = new Subject<boolean>();
  // notificationsCount: number = 0;
  interval: any;
  raioX?: RaioXQuery['raiox'];

  constructor(
    public translations: TranslationsLayoutService,
    public responsivenessObserver: LayoutService,
    private popoverController: PopoverController,
    private router: Router,
    public userService: UserService,
    private notificationService: NotificationService,
    private modalController: ModalController,
    private experienceService: ExperienceService
  ) {}

  get user() {
    return this.userService.getUser()?.currentUser;
  }

  get avatarSrc() {
    return this.userService.getAvatarSrc(this.user!);
  }

  get notificationsCount() {
    return this.notificationService.notificationsCount;
  }

  ngOnInit() {
    this.countNotifications();
    this.hasRating();
    if (this.user?.role === UserRole.Student) {
      this.getRaioX();
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  async getRaioX() {
    this.raioX = await this.experienceService.getRaioX();
  }

  async hasRating() {
    const notifications = await this.notificationService.list({
      readed: { value: [false], operation: FilterOperation.Equals },
      type: {
        value: [NotificationType.Rating],
        operation: FilterOperation.Equals,
      },
    });

    if (notifications.length > 0) {
      this.openRatingModal();
    }
  }

  async openRatingModal() {
    const id = 'rememberRating';
    const modal = await this.modalController.create({
      component: SharedModalRememberRatingComponent,
      cssClass: '--shared-modal-structure',
      id,
      componentProps: {
        id,
      },
    });

    await modal.present();
  }

  async countNotifications() {
    await this.notificationService.updateViewCount();

    this.interval = setInterval(async () => {
      await this.notificationService.updateViewCount();
    }, 1000 * 60);
  }

  toggleSideMenu() {
    this.isOpenSideMenu = !this.isOpenSideMenu;
    this.sideMenuSubject.next(this.isOpenSideMenu);
  }

  async presentPopoverNotifications(event: Event) {
    const popover = await this.popoverController.create({
      component: NotificationPopOverComponent,
      cssClass: '--notification-pop-over',
      event: event,
      translucent: true,
      mode: 'ios',
    });
    await popover.present();
  }

  async presentPopoverProfile(event: Event) {
    const popover = await this.popoverController.create({
      component: UserPopOverProfileComponent,
      cssClass: '--user-pop-over-profile',
      event: event,
      translucent: true,
      mode: 'ios',
    });
    await popover.present();
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }
}
