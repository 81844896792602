import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { UserService } from 'src/app/user/services/user/user.service';
import { UserRole } from 'src/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private userService: UserService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.redirectByRoles(route, state);
  }

  async canLoad(route: Route, segments: UrlSegment[]) {
    // console.log('canLoad');
    // console.log(route);
    // console.log(segments);
    return true;
    // return this.checkIsLoggedIn();
  }

  private async redirectByRoles(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const role = this.userService.getUser()?.currentUser.role;

    if (state.url === '/user/profile') {
      switch (role) {
        case UserRole.Student:
          this.router.navigate(['/student/profile']);
          break;
        case UserRole.Host:
          this.router.navigate(['/host/profile']);
          break;
      }
    } else if (state.url === '/experience/home') {
      switch (role) {
        case UserRole.Host:
          this.router.navigate(['/schedule/list-host']);
          break;
      }
    }
    // console.log('canActivate');
    // console.log(route.url);
    // console.log(state);

    return true;
  }
}
