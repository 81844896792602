import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthLayersGQL, AuthLayersInput } from '../../../generated/graphql';

@Injectable()
export class LayersService {
  constructor(private authLayersGQL: AuthLayersGQL) {}

  async authLayers(input: AuthLayersInput) {
    const result = await firstValueFrom(
      this.authLayersGQL.mutate(
        { args: input },
        { context: { isToLoad: true } }
      )
    );
    return result.data?.authLayers;
  }
}
