import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { SharedFormSelectOptionModel } from 'src/app/core/types/interfaces/shared-form-select-option.model';

interface SharedFormInsertListOption {
  value: string;
  option: SharedFormSelectOptionModel['code'];
}
@Component({
  selector: 'shared-form-insert-list',
  templateUrl: './shared-form-insert-list.component.html',
  styleUrls: ['./shared-form-insert-list.component.scss'],
})
export class SharedFormInsertListComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() type: 'text' | 'password' | 'number' | 'tel' = 'text';
  @Input() title: string;
  @Input() placeholder: string;
  @Input() helpText: string;
  @Input() iconSrc: string;
  @Input() itemValidator: ValidatorFn | null;
  @Input() options: Array<SharedFormSelectOptionModel>;
  @Input() dataTestId?: string;
  list: Array<SharedFormInsertListOption | string | number | boolean> = [];
  formGroup: FormGroup;

  constructor(
    public translations: TranslationsLayoutService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      newItem: [{ value: '', disabled: false }, this.itemValidator],
    });

    this.list = this.parentFormGroup.controls[this.controlName].value;
    this.subscribeOnFormValueChange();
  }

  subscribeOnFormValueChange() {
    const control = this.parentFormGroup.controls[this.controlName];

    control.valueChanges.subscribe((value: any) => {
      if (value === null) {
        this.list = [];
      }
    });
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  insertItem() {
    if (this.options) {
      this.list.push({
        value: this.formGroup.controls['newItem'].value,
        option: this.options[0].code,
      });
    } else {
      this.list.push(this.formGroup.controls['newItem'].value);
    }
    this.parentFormGroup.controls[this.controlName].setValue(this.list);
    this.formGroup.controls['newItem'].setValue('');
  }

  removeItem(index: number) {
    this.list.splice(index, 1);
    this.parentFormGroup.controls[this.controlName].setValue(this.list);
  }

  keypress(event: KeyboardEvent) {
    if (!this.formGroup.controls['newItem'].valid) return;

    if (
      event.key === 'Enter' &&
      this.formGroup.controls['newItem'].value.trim().length > 0
    )
      this.insertItem();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
  }

  checkControlIsValid() {
    if (
      this.parentFormGroup.controls[this.controlName].valid ||
      this.formGroup.controls['newItem'].valid
    ) {
      return true;
    }
    return false;
  }

  selectChange(event: Event, index: number) {
    (this.list[index] as SharedFormInsertListOption).option = (
      event as CustomEvent
    ).detail.value;
    this.parentFormGroup.controls[this.controlName].setValue(this.list);
  }
}
