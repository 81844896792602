import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'shared-form-time-input',
  templateUrl: './shared-form-time-input.component.html',
  styleUrls: ['./shared-form-time-input.component.scss'],
})
export class SharedFormTimeInputComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() placeholder: string = '';

  constructor() {}

  ngOnInit() {}

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
