import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { LayoutService } from './core/services/layout/layout.service';
import { LayersService } from './layers/services/integration.service';
import { UserService } from './user/services/user/user.service';

declare var LayersPortal: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  loading: Promise<HTMLIonLoadingElement>;

  constructor(
    public userService: UserService,
    public layoutService: LayoutService,
    private platform: Platform,
    private integrationService: LayersService,
    private router: Router
  ) {
    this.platform.ready().then(() => {
      this.loadLayersPortalScript();
    });
  }

  loadLayersPortalScript() {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://js.layers.digital/v1/LayersPortal.js';
    scriptElement.onload = () => {
      this.initLayersPortal();
    };
    document.head.appendChild(scriptElement);
  }

  async initLayersPortal() {
    LayersPortal.on('connected', (data: any) => {
      this.onLayersPortalConnected(data);
    });
  }

  async onLayersPortalConnected(data: any) {
    const result = await this.integrationService.authLayers({
      accountId: data.accountId,
      communityId: data.communityId,
      session: data.session,
      userId: data.userId,
    });

    console.log('result', result);

    if (result?.accessToken) {
      localStorage.setItem(environment.tokenName, result.accessToken);
      this.router.navigate(['/experience/home']);
    }
  }
}
