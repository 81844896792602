import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** List of E-mails that can be registered as a student */
export type AllowedStudentEmail = {
  __typename?: 'AllowedStudentEmail';
  email: Scalars['String'];
  id: Scalars['Int'];
  institution: Institution;
  studentUser?: Maybe<StudentUser>;
};

export type AuthLayersInput = {
  accountId: Scalars['String'];
  communityId: Scalars['String'];
  session: Scalars['String'];
  userId: Scalars['String'];
};

export type AuthLogin = {
  __typename?: 'AuthLogin';
  accessToken: Scalars['String'];
};

export type AuthLoginByIntegration = {
  __typename?: 'AuthLoginByIntegration';
  url: Scalars['String'];
};

export type CouponCode = {
  __typename?: 'CouponCode';
  code: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  studentUsers?: Maybe<Array<StudentUser>>;
};

export type CreateEnrollmentInput = {
  schedule: GraphQlRelationInput;
  viewType?: InputMaybe<EnrollmentViewType>;
  wasPresent?: InputMaybe<Scalars['Boolean']>;
};

export type CreateEnrollmentRecordedInput = {
  experience: GraphQlRelationInput;
};

export type CreateExperienceInput = {
  averageSalary: Scalars['Float'];
  description: Scalars['String'];
  imageSrcIndex: Scalars['Float'];
  imageSrcs: Array<Scalars['String']>;
  isHighlighted: Scalars['Boolean'];
  itineraries: Array<GraphQlRelationInput>;
  jobScore: Scalars['Float'];
  material?: InputMaybe<Scalars['String']>;
  profession: GraphQlRelationInput;
  subtitle: Scalars['String'];
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type CreateHostUserInput = {
  bio: Scalars['String'];
  birthDate: Scalars['DateTime'];
  city: Scalars['String'];
  cpf: Scalars['String'];
  educationLevel: HostUserEducationLevel;
  educationalInstitution: Scalars['String'];
  isVolunteer: Scalars['Boolean'];
  itinerary: GraphQlRelationInput;
  linkedin: Scalars['String'];
  neighborhood: Scalars['String'];
  state: Scalars['String'];
  telephone: Scalars['String'];
  user: CreateUserInput;
};

export type CreateInstitutionByIntegrationInput = {
  externalId: Scalars['String'];
  maxStudents: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type CreateInstitutionInput = {
  allowedStudentsEmails?: InputMaybe<Array<Scalars['String']>>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  isAllowedRegisterByInstitutionalEmail: Scalars['Boolean'];
  isAllowedRegisterByStudentEmail: Scalars['Boolean'];
  isAllowedStudentsLogin: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  maxStudents: Scalars['Int'];
  name: Scalars['String'];
  project?: InputMaybe<Project>;
  url?: InputMaybe<Scalars['String']>;
};

export type CreateItineraryInput = {
  description: Scalars['String'];
  imageSrc: Scalars['String'];
  name: Scalars['String'];
};

export type CreateJwtJitsiInput = {
  schedule?: InputMaybe<GraphQlRelationInput>;
};

export type CreateProfessionInput = {
  name: Scalars['String'];
};

export type CreateProfessorB2CInput = {
  birthDate: Scalars['DateTime'];
  city: Scalars['String'];
  cpf: Scalars['String'];
  educationLevel: StudentUserEducationLevel;
  ethnicity?: InputMaybe<StudentUserEthnicity>;
  facebook?: InputMaybe<Scalars['String']>;
  familyIncome?: InputMaybe<StudentUserFamilyIncome>;
  instagram?: InputMaybe<Scalars['String']>;
  /** For subscription users only */
  institutionName: Scalars['String'];
  integrationCode: IntegrationCode;
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  productName: ProductName;
  profession?: InputMaybe<GraphQlRelationInput>;
  state: Scalars['String'];
  telephone: Scalars['String'];
  user: CreateUserInput;
};

export type CreateProfessorForSubscriptionInput = {
  city: Scalars['String'];
  cpf: Scalars['String'];
  educationLevel: StudentUserEducationLevel;
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  /** For subscription users only */
  institutionName: Scalars['String'];
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  profession?: InputMaybe<GraphQlRelationInput>;
  state: Scalars['String'];
  telephone: Scalars['String'];
  user: CreateUserInput;
  /** For subscription users only */
  voucherSecret: Scalars['String'];
};

export type CreateProfessorWithCouponInput = {
  city: Scalars['String'];
  code: Scalars['String'];
  cpf: Scalars['String'];
  educationLevel: StudentUserEducationLevel;
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  /** For subscription users only */
  institutionName: Scalars['String'];
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  profession?: InputMaybe<GraphQlRelationInput>;
  state: Scalars['String'];
  telephone: Scalars['String'];
  user: CreateUserInput;
};

export type CreateScheduleInput = {
  endDate: Scalars['DateTime'];
  experience: GraphQlRelationInput;
  hostUser: GraphQlRelationInput;
  limit?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  videoURL: Scalars['String'];
};

export type CreateSettingInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateStudentUserByIntegrationInput = {
  classes?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  institution: CreateInstitutionByIntegrationInput;
  name: Scalars['String'];
};

export type CreateStudentUserForSubscriptionInput = {
  birthDate: Scalars['DateTime'];
  cep?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  cpf: Scalars['String'];
  educationLevel: StudentUserEducationLevel;
  ethnicity?: InputMaybe<StudentUserEthnicity>;
  facebook?: InputMaybe<Scalars['String']>;
  familyIncome?: InputMaybe<StudentUserFamilyIncome>;
  instagram?: InputMaybe<Scalars['String']>;
  /** For subscription users only */
  institutionName: Scalars['String'];
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number?: InputMaybe<Scalars['String']>;
  profession?: InputMaybe<GraphQlRelationInput>;
  state: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  telephone: Scalars['String'];
  user: CreateUserInput;
  /** For subscription users only */
  voucherSecret: Scalars['String'];
};

export type CreateStudentUserInput = {
  birthDate: Scalars['DateTime'];
  cep?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  cpf: Scalars['String'];
  educationLevel: StudentUserEducationLevel;
  ethnicity?: InputMaybe<StudentUserEthnicity>;
  facebook?: InputMaybe<Scalars['String']>;
  familyIncome?: InputMaybe<StudentUserFamilyIncome>;
  instagram?: InputMaybe<Scalars['String']>;
  institution: GraphQlRelationInput;
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number?: InputMaybe<Scalars['String']>;
  profession?: InputMaybe<GraphQlRelationInput>;
  state: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  telephone: Scalars['String'];
  user: CreateUserInput;
};

export type CreateStudentUserPortugalInput = {
  birthDate: Scalars['DateTime'];
  cep?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  civilCode?: InputMaybe<Scalars['String']>;
  educationLevel: StudentUserEducationLevel;
  institution: GraphQlRelationInput;
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  nationality: Scalars['String'];
  neighborhood: Scalars['String'];
  number?: InputMaybe<Scalars['String']>;
  profession?: InputMaybe<GraphQlRelationInput>;
  state: Scalars['String'];
  street?: InputMaybe<Scalars['String']>;
  telephone: Scalars['String'];
  user: CreateUserInput;
};

export type CreateTicketInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  subject: Scalars['String'];
  type: Scalars['String'];
};

export type CreateTicketOutput = {
  __typename?: 'CreateTicketOutput';
  success: Scalars['Boolean'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  gender: UserGender;
  integrationId?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  password: Scalars['String'];
  project?: InputMaybe<Project>;
  role?: InputMaybe<UserRole>;
};

export type DeleteStudentUserInput = {
  id: Scalars['Int'];
  password?: InputMaybe<Scalars['String']>;
};

export type Enrollment = {
  __typename?: 'Enrollment';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  ratingComment?: Maybe<Scalars['String']>;
  schedule: Schedule;
  studentUser: StudentUser;
  updatedAt: Scalars['DateTime'];
  viewType: EnrollmentViewType;
  wasPresent?: Maybe<Scalars['Boolean']>;
};

export enum EnrollmentViewType {
  Live = 'LIVE',
  Recorded = 'RECORDED'
}

export type Experience = {
  __typename?: 'Experience';
  averageSalary: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  imageSrcIndex: Scalars['Float'];
  imageSrcs: Array<Scalars['String']>;
  isHighlighted: Scalars['Boolean'];
  itineraries: Array<Itinerary>;
  jobScore: Scalars['Float'];
  material?: Maybe<Scalars['String']>;
  profession: Profession;
  rating?: Maybe<ExperienceRating>;
  schedules: Array<Schedule>;
  subtitle: Scalars['String'];
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  videoUrl?: Maybe<Scalars['String']>;
};


export type ExperienceSchedulesArgs = {
  filters?: InputMaybe<FilterExperienceSchedulesInput>;
};

export type ExperienceRating = {
  __typename?: 'ExperienceRating';
  count: Scalars['Float'];
  value?: Maybe<Scalars['Float']>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FilterBooleanInput = {
  operation: FilterOperation;
  value?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type FilterDateInput = {
  operation: FilterOperation;
  value?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type FilterEnrollmentInput = {
  wasPresent?: InputMaybe<Scalars['Boolean']>;
};

export type FilterExperienceInput = {
  description?: InputMaybe<FilterStringInput>;
  isHighlighted?: InputMaybe<FilterBooleanInput>;
  itineraries?: InputMaybe<Array<FilterItineraryInput>>;
  schedules?: InputMaybe<FilterBooleanInput>;
  subtitle?: InputMaybe<FilterStringInput>;
  tags?: InputMaybe<FilterStringInput>;
  title?: InputMaybe<FilterStringInput>;
};

export type FilterExperienceSchedulesInput = {
  endDate?: InputMaybe<FilterDateInput>;
  hostUser?: InputMaybe<FilterHostUserInput>;
  startDate?: InputMaybe<FilterDateInput>;
};

export type FilterHostUserInput = {
  id?: InputMaybe<FilterNumberInput>;
  name?: InputMaybe<FilterStringInput>;
};

export type FilterItineraryInput = {
  id?: InputMaybe<FilterNumberInput>;
  name?: InputMaybe<FilterStringInput>;
};

export type FilterNotificationInput = {
  readed?: InputMaybe<FilterBooleanInput>;
  type?: InputMaybe<FilterNotificationType>;
};

export type FilterNotificationType = {
  operation: FilterOperation;
  value?: InputMaybe<Array<NotificationType>>;
};

export type FilterNumberInput = {
  operation: FilterOperation;
  value?: InputMaybe<Array<Scalars['Float']>>;
};

export enum FilterOperation {
  Equals = 'EQUALS',
  Greater = 'GREATER',
  In = 'IN',
  Isnotnull = 'ISNOTNULL',
  Less = 'LESS',
  Like = 'LIKE'
}

export type FilterScheduleInput = {
  endDate?: InputMaybe<FilterDateInput>;
  hostUser?: InputMaybe<FilterHostUserInput>;
  startDate?: InputMaybe<FilterDateInput>;
};

export type FilterStringInput = {
  operation: FilterOperation;
  value?: InputMaybe<Array<Scalars['String']>>;
};

export type GraphQlRelationInput = {
  id: Scalars['Int'];
};

export type HostUser = {
  __typename?: 'HostUser';
  bio: Scalars['String'];
  birthDate: Scalars['DateTime'];
  cep?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  cpf: Scalars['String'];
  createdAt: Scalars['DateTime'];
  educationLevel: HostUserEducationLevel;
  educationalInstitution: Scalars['String'];
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  instagram?: Maybe<Scalars['String']>;
  isApproved: Scalars['Boolean'];
  isVolunteer: Scalars['Boolean'];
  itinerary: Itinerary;
  linkedin?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  schedules?: Maybe<Array<Schedule>>;
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum HostUserEducationLevel {
  College = 'COLLEGE',
  Doctor = 'DOCTOR',
  Elementary = 'ELEMENTARY',
  Graduate = 'GRADUATE',
  Master = 'MASTER',
  Postgraduate = 'POSTGRADUATE',
  Secondary = 'SECONDARY',
  Uninformed = 'UNINFORMED'
}

export type Institution = {
  __typename?: 'Institution';
  allowedStudentsEmails?: Maybe<Array<AllowedStudentEmail>>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  domains?: Maybe<Array<Scalars['String']>>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  integration?: Maybe<Integration>;
  isAllowedRegisterByInstitutionalEmail: Scalars['Boolean'];
  isAllowedRegisterByStudentEmail: Scalars['Boolean'];
  isAllowedStudentsLogin: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  maxStudents: Scalars['Int'];
  name: Scalars['String'];
  project?: Maybe<Project>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type Integration = {
  __typename?: 'Integration';
  active: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  domains?: Maybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  institutions: Array<Institution>;
  name: Scalars['String'];
  secret: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: Array<User>;
};

export enum IntegrationCode {
  BlackNovember = 'BLACK_NOVEMBER',
  ClientB2C = 'CLIENT_B2C'
}

export type Itinerary = {
  __typename?: 'Itinerary';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  experiences?: Maybe<Array<Experience>>;
  hostUsers: Array<HostUser>;
  id: Scalars['Int'];
  imageSrc: Scalars['String'];
  name: Scalars['String'];
  project?: Maybe<Project>;
  studentUsers: Array<StudentUser>;
  updatedAt: Scalars['DateTime'];
};

export type JitsiDestroyRoom = {
  jwt: Scalars['String'];
  roomName: Scalars['String'];
};

export type Log = {
  __typename?: 'Log';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  type: LogType;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum LogType {
  Login = 'LOGIN'
}

export type LoginIntegrationInput = {
  secret: Scalars['String'];
};

export type LoginUserByIntegrationInput = {
  email: Scalars['String'];
};

export type LoginUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authLayers: AuthLogin;
  createEnrollment: Enrollment;
  createEnrollmentRecorded: Enrollment;
  createExperience: Experience;
  createHostUser: HostUser;
  createInstitution: Institution;
  createItinerary: Itinerary;
  createJWTJitsi: Scalars['String'];
  createOrUpdateVocationalTestResult: VocationalTest;
  createProfession: Profession;
  createProfessorB2C: Scalars['String'];
  createProfessorForSubscription: StudentUser;
  createProfessorWithCoupon: StudentUser;
  createSchedule: Schedule;
  createSetting: Setting;
  createStudentUser: StudentUser;
  createStudentUserByIntegration: StudentUserByIntegration;
  createStudentUserForSubscription: StudentUser;
  createStudentUserPortugal: StudentUser;
  createTicket: CreateTicketOutput;
  finishSchedule: Scalars['Boolean'];
  jitsiDestroyRoom: Scalars['String'];
  loginIntegration: AuthLogin;
  loginUserByIntegration: AuthLoginByIntegration;
  loginUserSerenPass: AuthLogin;
  loginUserSerenPassPortugal: AuthLogin;
  loginUserSerenPro: AuthLogin;
  removeEnrollment: Enrollment;
  removeExperience: Experience;
  removeHostUser: HostUser;
  removeInstitution?: Maybe<Institution>;
  removeMaterial: UpdateResult;
  removeSchedule: Schedule;
  removeStudentUser: StudentUser;
  removeUser: User;
  requestPasswordRecovery: Scalars['Boolean'];
  updateEnrollment: Enrollment;
  updateExperience: UpdateResult;
  updateHostUser: HostUser;
  updateInstitution: Institution;
  updateNotification: Notification;
  updateSchedule: Schedule;
  updateSetting: Setting;
  updateStudentUser: StudentUser;
  updateUser: User;
  uploadImage: File;
  uploadMaterial: File;
  uploadUserAvatar: File;
  user: User;
};


export type MutationAuthLayersArgs = {
  args: AuthLayersInput;
};


export type MutationCreateEnrollmentArgs = {
  createEnrollmentInput: CreateEnrollmentInput;
};


export type MutationCreateEnrollmentRecordedArgs = {
  createEnrollmentRecordedInput: CreateEnrollmentRecordedInput;
};


export type MutationCreateExperienceArgs = {
  createExperienceInput: CreateExperienceInput;
};


export type MutationCreateHostUserArgs = {
  createHostUserInput: CreateHostUserInput;
};


export type MutationCreateInstitutionArgs = {
  createInstitutionInput: CreateInstitutionInput;
};


export type MutationCreateItineraryArgs = {
  createItineraryInput: CreateItineraryInput;
};


export type MutationCreateJwtJitsiArgs = {
  createJWTJitsiInput: CreateJwtJitsiInput;
};


export type MutationCreateOrUpdateVocationalTestResultArgs = {
  input: VocationalTestInput;
};


export type MutationCreateProfessionArgs = {
  createProfessionInput: CreateProfessionInput;
};


export type MutationCreateProfessorB2CArgs = {
  createProfessorB2CInput: CreateProfessorB2CInput;
};


export type MutationCreateProfessorForSubscriptionArgs = {
  createProfessorForSubscriptionInput: CreateProfessorForSubscriptionInput;
};


export type MutationCreateProfessorWithCouponArgs = {
  input: CreateProfessorWithCouponInput;
};


export type MutationCreateScheduleArgs = {
  createScheduleInput: CreateScheduleInput;
};


export type MutationCreateSettingArgs = {
  createSettingInput: CreateSettingInput;
};


export type MutationCreateStudentUserArgs = {
  createStudentUserInput: CreateStudentUserInput;
};


export type MutationCreateStudentUserByIntegrationArgs = {
  args: CreateStudentUserByIntegrationInput;
};


export type MutationCreateStudentUserForSubscriptionArgs = {
  createStudentUserForSubscriptionInput: CreateStudentUserForSubscriptionInput;
};


export type MutationCreateStudentUserPortugalArgs = {
  createStudentUserPortugalInput: CreateStudentUserPortugalInput;
};


export type MutationCreateTicketArgs = {
  createTicketInput: CreateTicketInput;
};


export type MutationFinishScheduleArgs = {
  schedule: GraphQlRelationInput;
};


export type MutationJitsiDestroyRoomArgs = {
  jitsiDestroyRoom: JitsiDestroyRoom;
};


export type MutationLoginIntegrationArgs = {
  args: LoginIntegrationInput;
};


export type MutationLoginUserByIntegrationArgs = {
  args: LoginUserByIntegrationInput;
};


export type MutationLoginUserSerenPassArgs = {
  loginUserInput: LoginUserInput;
};


export type MutationLoginUserSerenPassPortugalArgs = {
  loginUserInput: LoginUserInput;
};


export type MutationLoginUserSerenProArgs = {
  loginUserInput: LoginUserInput;
};


export type MutationRemoveEnrollmentArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveExperienceArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveHostUserArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveInstitutionArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveMaterialArgs = {
  experienceId: Scalars['Int'];
};


export type MutationRemoveScheduleArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveStudentUserArgs = {
  deleteStudentUserInput: DeleteStudentUserInput;
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationRequestPasswordRecoveryArgs = {
  requestPasswordRecoveryInput: RequestPasswordRecoveryInput;
};


export type MutationUpdateEnrollmentArgs = {
  updateEnrollmentInput: UpdateEnrollmentInput;
};


export type MutationUpdateExperienceArgs = {
  updateExperienceInput: UpdateExperienceInput;
};


export type MutationUpdateHostUserArgs = {
  updateHostUserInput: UpdateHostUserInput;
};


export type MutationUpdateInstitutionArgs = {
  updateInstitutionInput: UpdateInstitutionInput;
};


export type MutationUpdateNotificationArgs = {
  updateNotificationInput: UpdateNotificationInput;
};


export type MutationUpdateScheduleArgs = {
  updateScheduleInput: UpdateScheduleInput;
};


export type MutationUpdateSettingArgs = {
  updateSettingInput: UpdateSettingInput;
};


export type MutationUpdateStudentUserArgs = {
  updateStudentUserInput: UpdateStudentUserInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadMaterialArgs = {
  experienceId: Scalars['Int'];
  file: Scalars['Upload'];
};


export type MutationUploadUserAvatarArgs = {
  file: Scalars['Upload'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationUserArgs = {
  createUserInput: CreateUserInput;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  readed: Scalars['Boolean'];
  text: Scalars['String'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  user: User;
};

export enum NotificationType {
  Default = 'DEFAULT',
  Rating = 'RATING'
}

export type OrderExperienceInput = {
  averageSalary?: InputMaybe<OrderType>;
  jobScore?: InputMaybe<OrderType>;
  title?: InputMaybe<OrderType>;
};

export type OrderHostInput = {
  user?: InputMaybe<OrderUserInput>;
};

export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderUserInput = {
  name?: InputMaybe<OrderType>;
};

export enum ProductName {
  PagarmeBlackNovemberPrice = 'PAGARME_BLACK_NOVEMBER_PRICE',
  PagarmeVagasPrice = 'PAGARME_VAGAS_PRICE'
}

export type Profession = {
  __typename?: 'Profession';
  createdAt: Scalars['DateTime'];
  experiences?: Maybe<Array<Experience>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  studentUser?: Maybe<Array<StudentUser>>;
  updatedAt: Scalars['DateTime'];
};

export enum Project {
  SerenPass = 'serenPass',
  SerenPassPortugal = 'serenPassPortugal',
  SerenPro = 'serenPro'
}

export type Query = {
  __typename?: 'Query';
  currentHostUser: HostUser;
  currentStudentUser: StudentUser;
  currentUser: User;
  enrollment: Enrollment;
  enrollments: Array<Enrollment>;
  experience: Experience;
  experiences: Array<Experience>;
  findCollected: Experience;
  hostUser: HostUser;
  hostUsers: Array<HostUser>;
  institution: Institution;
  institutions: Array<Institution>;
  itineraries: Array<Itinerary>;
  notifications: Array<Notification>;
  professions: Array<Profession>;
  raiox: RaioX;
  recommendedExperiences: Array<Experience>;
  schedule: Schedule;
  schedules: Array<Schedule>;
  settings: Array<Setting>;
  studentUser: StudentUser;
  studentUsers: Array<StudentUser>;
  vocationalTestResult?: Maybe<VocationalTest>;
};


export type QueryEnrollmentArgs = {
  id: Scalars['Int'];
};


export type QueryEnrollmentsArgs = {
  filters?: InputMaybe<FilterEnrollmentInput>;
};


export type QueryExperienceArgs = {
  id: Scalars['Int'];
};


export type QueryExperiencesArgs = {
  filters?: InputMaybe<Array<FilterExperienceInput>>;
  orders?: InputMaybe<OrderExperienceInput>;
};


export type QueryFindCollectedArgs = {
  id: Scalars['Int'];
};


export type QueryHostUserArgs = {
  id: Scalars['Int'];
};


export type QueryHostUsersArgs = {
  orders?: InputMaybe<OrderHostInput>;
};


export type QueryInstitutionArgs = {
  id: Scalars['Int'];
};


export type QueryInstitutionsArgs = {
  isPublished?: InputMaybe<Scalars['Boolean']>;
  project?: InputMaybe<Project>;
};


export type QueryItinerariesArgs = {
  project?: InputMaybe<Project>;
};


export type QueryNotificationsArgs = {
  filters?: InputMaybe<FilterNotificationInput>;
};


export type QueryScheduleArgs = {
  id: Scalars['Int'];
};


export type QuerySchedulesArgs = {
  filters?: InputMaybe<FilterScheduleInput>;
};


export type QueryStudentUserArgs = {
  id: Scalars['Int'];
};

export type RaioX = {
  __typename?: 'RaioX';
  experiencesLived: Array<Enrollment>;
  latentAreas: Array<Scalars['String']>;
  raioXMinEnrollment: Scalars['Int'];
  youMayAlsoLike: Array<Experience>;
};

export type RequestPasswordRecoveryInput = {
  email: Scalars['String'];
};

export type Schedule = {
  __typename?: 'Schedule';
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  enrollments: Array<Enrollment>;
  experience: Experience;
  hostUser: HostUser;
  id: Scalars['Int'];
  limit: Scalars['Float'];
  occurredEndDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  videoURL: Scalars['String'];
};

export type Setting = {
  __typename?: 'Setting';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type StudentUser = {
  __typename?: 'StudentUser';
  allowedStudentEmail?: Maybe<AllowedStudentEmail>;
  birthDate?: Maybe<Scalars['DateTime']>;
  cep?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  civilCode?: Maybe<Scalars['String']>;
  couponCode?: Maybe<CouponCode>;
  cpf: Scalars['String'];
  createdAt: Scalars['DateTime'];
  educationLevel: StudentUserEducationLevel;
  enrollments: Array<Itinerary>;
  ethnicity: StudentUserEthnicity;
  facebook?: Maybe<Scalars['String']>;
  familyIncome: StudentUserFamilyIncome;
  hoursExperienced: Scalars['Float'];
  id: Scalars['Int'];
  instagram?: Maybe<Scalars['String']>;
  institution: Institution;
  /** Mainly for subscription students, their insitution relation will be a default one, but they will fill the name as an open  answer  */
  institutionName?: Maybe<Scalars['String']>;
  itineraries: Array<Itinerary>;
  linkedin?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  neighborhood: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  /** Profissão preferida */
  profession?: Maybe<Profession>;
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  vocationalTest: VocationalTest;
  voucher?: Maybe<StudentUser>;
};

export type StudentUserByIntegration = {
  __typename?: 'StudentUserByIntegration';
  email: Scalars['String'];
  name: Scalars['String'];
};

export enum StudentUserEducationLevel {
  Doctor = 'DOCTOR',
  FirstGradeOfHighSchool = 'FIRST_GRADE_OF_HIGH_SCHOOL',
  Graduate = 'GRADUATE',
  Master = 'MASTER',
  NinthGradeOfElementarySchool = 'NINTH_GRADE_OF_ELEMENTARY_SCHOOL',
  Postgraduate = 'POSTGRADUATE',
  PtEighthGradeOfElementarySchool = 'PT_EIGHTH_GRADE_OF_ELEMENTARY_SCHOOL',
  PtEleventhGradeOfHighSchool = 'PT_ELEVENTH_GRADE_OF_HIGH_SCHOOL',
  PtNinthGradeOfElementarySchool = 'PT_NINTH_GRADE_OF_ELEMENTARY_SCHOOL',
  PtTenthGradeOfHighSchool = 'PT_TENTH_GRADE_OF_HIGH_SCHOOL',
  PtTwelfthGradeOfHighSchool = 'PT_TWELFTH_GRADE_OF_HIGH_SCHOOL',
  Secondary = 'SECONDARY',
  SecondGradeOfHighSchool = 'SECOND_GRADE_OF_HIGH_SCHOOL',
  ThirdGradeOfHighSchool = 'THIRD_GRADE_OF_HIGH_SCHOOL',
  Uninformed = 'UNINFORMED'
}

export enum StudentUserEthnicity {
  Black = 'BLACK',
  Brown = 'BROWN',
  Indigenous = 'INDIGENOUS',
  NotSelected = 'NOT_SELECTED',
  NoDeclaration = 'NO_DECLARATION',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export enum StudentUserFamilyIncome {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  NotSelected = 'NOT_SELECTED'
}

export type UpdateEnrollmentInput = {
  id: Scalars['Int'];
  rating?: InputMaybe<Scalars['Int']>;
  ratingComment?: InputMaybe<Scalars['String']>;
  viewType?: InputMaybe<EnrollmentViewType>;
  wasPresent?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateExperienceInput = {
  averageSalary?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  imageSrcIndex?: InputMaybe<Scalars['Float']>;
  imageSrcs?: InputMaybe<Array<Scalars['String']>>;
  isHighlighted?: InputMaybe<Scalars['Boolean']>;
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  jobScore?: InputMaybe<Scalars['Float']>;
  material?: InputMaybe<Scalars['String']>;
  profession?: InputMaybe<GraphQlRelationInput>;
  subtitle?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateHostUserInput = {
  bio?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  educationLevel?: InputMaybe<HostUserEducationLevel>;
  educationalInstitution?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  instagram?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isVolunteer?: InputMaybe<Scalars['Boolean']>;
  itinerary?: InputMaybe<GraphQlRelationInput>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UpdateUserInput>;
};

export type UpdateInstitutionInput = {
  allowedStudentsEmails?: InputMaybe<Array<Scalars['String']>>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  isAllowedRegisterByInstitutionalEmail?: InputMaybe<Scalars['Boolean']>;
  isAllowedRegisterByStudentEmail?: InputMaybe<Scalars['Boolean']>;
  isAllowedStudentsLogin?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  maxStudents?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateNotificationInput = {
  id: Scalars['Int'];
  readed: Scalars['Boolean'];
};

export type UpdateResult = {
  __typename?: 'UpdateResult';
  success: Scalars['Boolean'];
};

export type UpdateScheduleInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  experience?: InputMaybe<GraphQlRelationInput>;
  hostUser?: InputMaybe<GraphQlRelationInput>;
  id: Scalars['Int'];
  limit?: InputMaybe<Scalars['Float']>;
  occurredEndDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  videoURL?: InputMaybe<Scalars['String']>;
};

export type UpdateSettingInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentUserInput = {
  birthDate?: InputMaybe<Scalars['DateTime']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  educationLevel?: InputMaybe<StudentUserEducationLevel>;
  ethnicity?: InputMaybe<StudentUserEthnicity>;
  facebook?: InputMaybe<Scalars['String']>;
  familyIncome?: InputMaybe<StudentUserFamilyIncome>;
  id: Scalars['Int'];
  instagram?: InputMaybe<Scalars['String']>;
  institution?: InputMaybe<GraphQlRelationInput>;
  itineraries?: InputMaybe<Array<GraphQlRelationInput>>;
  linkedin?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  profession?: InputMaybe<GraphQlRelationInput>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UpdateUserInput>;
};

export type UpdateUserInput = {
  avatarSrc?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<UserGender>;
  id: Scalars['Int'];
  integrationId?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isEmailVerified?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Project>;
  role?: InputMaybe<UserRole>;
};

export type User = {
  __typename?: 'User';
  activateAccountToken?: Maybe<Scalars['String']>;
  avatarSrc?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  expiration?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  gender: UserGender;
  hostUser?: Maybe<HostUser>;
  id: Scalars['Int'];
  integration?: Maybe<Integration>;
  isActive: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  logs?: Maybe<Array<Log>>;
  name: Scalars['String'];
  notifications: Array<Notification>;
  password: Scalars['String'];
  project?: Maybe<Project>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  role: UserRole;
  studentUser?: Maybe<StudentUser>;
  updatedAt: Scalars['DateTime'];
};

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Uninformed = 'UNINFORMED'
}

export enum UserRole {
  Admin = 'ADMIN',
  Host = 'HOST',
  Professor = 'PROFESSOR',
  Student = 'STUDENT'
}

export type VocationalTest = {
  __typename?: 'VocationalTest';
  createdAt: Scalars['DateTime'];
  distResultJson: Scalars['String'];
  id: Scalars['Int'];
  result?: Maybe<Scalars['String']>;
  studentUser: StudentUser;
  updateAt: Scalars['DateTime'];
};

export type VocationalTestInput = {
  distResultJson: Scalars['String'];
  result?: InputMaybe<Scalars['String']>;
};

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUserSerenPassPortugal: { __typename: 'AuthLogin', accessToken: string } };

export type RequestPasswordRecoveryMutationVariables = Exact<{
  input: RequestPasswordRecoveryInput;
}>;


export type RequestPasswordRecoveryMutation = { __typename?: 'Mutation', requestPasswordRecovery: boolean };

export type RemoveMaterialMutationVariables = Exact<{
  experienceId: Scalars['Int'];
}>;


export type RemoveMaterialMutation = { __typename?: 'Mutation', removeMaterial: { __typename?: 'UpdateResult', success: boolean } };

export type UploadMaterialMutationVariables = Exact<{
  file: Scalars['Upload'];
  experienceId: Scalars['Int'];
}>;


export type UploadMaterialMutation = { __typename?: 'Mutation', uploadMaterial: { __typename?: 'File', path: string } };

export type UploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadImageMutation = { __typename?: 'Mutation', uploadImage: { __typename?: 'File', path: string } };

export type UploadUserAvatarMutationVariables = Exact<{
  file: Scalars['Upload'];
  userId?: InputMaybe<Scalars['Int']>;
}>;


export type UploadUserAvatarMutation = { __typename?: 'Mutation', uploadUserAvatar: { __typename?: 'File', path: string } };

export type CreateEnrollmentRecordedMutationVariables = Exact<{
  input: CreateEnrollmentRecordedInput;
}>;


export type CreateEnrollmentRecordedMutation = { __typename?: 'Mutation', createEnrollmentRecorded: { __typename?: 'Enrollment', id: number, rating?: number | null, studentUser: { __typename?: 'StudentUser', id: number } } };

export type CreateEnrollmentMutationVariables = Exact<{
  input: CreateEnrollmentInput;
}>;


export type CreateEnrollmentMutation = { __typename?: 'Mutation', createEnrollment: { __typename?: 'Enrollment', id: number, rating?: number | null, studentUser: { __typename?: 'StudentUser', id: number } } };

export type EnrollmentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EnrollmentQuery = { __typename?: 'Query', enrollment: { __typename?: 'Enrollment', id: number, createdAt: any, rating?: number | null, ratingComment?: string | null, schedule: { __typename?: 'Schedule', experience: { __typename?: 'Experience', title: string, id: number } } } };

export type ListEnrollmentsQueryVariables = Exact<{
  filters?: InputMaybe<FilterEnrollmentInput>;
}>;


export type ListEnrollmentsQuery = { __typename?: 'Query', enrollments: Array<{ __typename?: 'Enrollment', id: number, wasPresent?: boolean | null, viewType: EnrollmentViewType, schedule: { __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, experience: { __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, imageSrcIndex: number, videoUrl?: string | null } } }> };

export type RemoveEnrollmentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveEnrollmentMutation = { __typename?: 'Mutation', removeEnrollment: { __typename: 'Enrollment' } };

export type UpdateEnrollmentMutationVariables = Exact<{
  input: UpdateEnrollmentInput;
}>;


export type UpdateEnrollmentMutation = { __typename?: 'Mutation', updateEnrollment: { __typename: 'Enrollment' } };

export type CreateExperienceMutationVariables = Exact<{
  input: CreateExperienceInput;
}>;


export type CreateExperienceMutation = { __typename?: 'Mutation', createExperience: { __typename?: 'Experience', id: number } };

export type ExperienceFindCollectedQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ExperienceFindCollectedQuery = { __typename?: 'Query', findCollected: { __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, imageSrcIndex: number, description: string, tags: Array<string>, material?: string | null, videoUrl?: string | null } };

export type ExperienceWithoutEnrollmentsQueryVariables = Exact<{
  id: Scalars['Int'];
  filterSchedule: FilterExperienceSchedulesInput;
}>;


export type ExperienceWithoutEnrollmentsQuery = { __typename?: 'Query', experience: { __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, isHighlighted: boolean, imageSrcIndex: number, description: string, rating?: { __typename?: 'ExperienceRating', value?: number | null, count: number } | null, profession: { __typename?: 'Profession', id: number, name: string }, itineraries: Array<{ __typename?: 'Itinerary', id: number, name: string }>, schedules: Array<{ __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, occurredEndDate?: any | null, hostUser: { __typename?: 'HostUser', id: number, user: { __typename?: 'User', name: string, avatarSrc?: string | null } } }> } };

export type ExperienceQueryVariables = Exact<{
  id: Scalars['Int'];
  filterSchedule: FilterExperienceSchedulesInput;
}>;


export type ExperienceQuery = { __typename?: 'Query', experience: { __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, isHighlighted: boolean, imageSrcIndex: number, description: string, tags: Array<string>, jobScore: number, averageSalary: number, material?: string | null, videoUrl?: string | null, rating?: { __typename?: 'ExperienceRating', value?: number | null, count: number } | null, profession: { __typename?: 'Profession', id: number, name: string }, itineraries: Array<{ __typename?: 'Itinerary', id: number, name: string }>, schedules: Array<{ __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, occurredEndDate?: any | null, enrollments: Array<{ __typename?: 'Enrollment', id: number, rating?: number | null, studentUser: { __typename?: 'StudentUser', id: number } }>, hostUser: { __typename?: 'HostUser', id: number, bio: string, user: { __typename?: 'User', name: string, avatarSrc?: string | null } } }> } };

export type ListExperiencesQueryVariables = Exact<{
  filters?: InputMaybe<Array<FilterExperienceInput> | FilterExperienceInput>;
  orders?: InputMaybe<OrderExperienceInput>;
}>;


export type ListExperiencesQuery = { __typename?: 'Query', experiences: Array<{ __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, isHighlighted: boolean, imageSrcIndex: number, description: string, jobScore: number, averageSalary: number, videoUrl?: string | null, itineraries: Array<{ __typename?: 'Itinerary', name: string }> }> };

export type RaioXQueryVariables = Exact<{ [key: string]: never; }>;


export type RaioXQuery = { __typename?: 'Query', raiox: { __typename?: 'RaioX', latentAreas: Array<string>, raioXMinEnrollment: number, youMayAlsoLike: Array<{ __typename?: 'Experience', id: number, title: string }>, experiencesLived: Array<{ __typename?: 'Enrollment', viewType: EnrollmentViewType, rating?: number | null, createdAt: any, schedule: { __typename?: 'Schedule', startDate: any, hostUser: { __typename?: 'HostUser', user: { __typename?: 'User', name: string } }, experience: { __typename?: 'Experience', id: number, title: string, profession: { __typename?: 'Profession', id: number, name: string }, itineraries: Array<{ __typename?: 'Itinerary', name: string }> } } }> } };

export type RecommendedExperienceQueryVariables = Exact<{ [key: string]: never; }>;


export type RecommendedExperienceQuery = { __typename?: 'Query', recommendedExperiences: Array<{ __typename?: 'Experience', id: number, title: string, subtitle: string, imageSrcs: Array<string>, imageSrcIndex: number, description: string }> };

export type RemoveExperienceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveExperienceMutation = { __typename?: 'Mutation', removeExperience: { __typename: 'Experience' } };

export type UpdateExperienceMutationVariables = Exact<{
  input: UpdateExperienceInput;
}>;


export type UpdateExperienceMutation = { __typename?: 'Mutation', updateExperience: { __typename?: 'UpdateResult', success: boolean } };

export type CreateHostUserMutationVariables = Exact<{
  input: CreateHostUserInput;
}>;


export type CreateHostUserMutation = { __typename?: 'Mutation', createHostUser: { __typename?: 'HostUser', id: number } };

export type CurrentHostSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentHostSchedulesQuery = { __typename?: 'Query', currentHostUser: { __typename?: 'HostUser', schedules?: Array<{ __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, experience: { __typename?: 'Experience', id: number, title: string } }> | null } };

export type CurrentHostUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentHostUserQuery = { __typename?: 'Query', currentHostUser: { __typename?: 'HostUser', id: number, number?: string | null, cep?: string | null, facebook?: string | null, linkedin?: string | null, instagram?: string | null, cpf: string, birthDate: any, state: string, city: string, neighborhood: string, telephone: string, educationLevel: HostUserEducationLevel, street?: string | null, educationalInstitution: string, isVolunteer: boolean, isApproved: boolean, bio: string, itinerary: { __typename?: 'Itinerary', id: number }, user: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean } } };

export type HostUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type HostUserQuery = { __typename?: 'Query', hostUser: { __typename?: 'HostUser', id: number, number?: string | null, cep?: string | null, facebook?: string | null, linkedin?: string | null, instagram?: string | null, cpf: string, birthDate: any, state: string, city: string, neighborhood: string, telephone: string, educationLevel: HostUserEducationLevel, street?: string | null, educationalInstitution: string, isVolunteer: boolean, isApproved: boolean, bio: string, itinerary: { __typename?: 'Itinerary', id: number }, user: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean } } };

export type ListHostsQueryVariables = Exact<{
  orders?: InputMaybe<OrderHostInput>;
}>;


export type ListHostsQuery = { __typename?: 'Query', hostUsers: Array<{ __typename?: 'HostUser', id: number, user: { __typename?: 'User', name: string } }> };

export type RemoveHostMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveHostMutation = { __typename?: 'Mutation', removeHostUser: { __typename: 'HostUser' } };

export type UpdateHostUserMutationVariables = Exact<{
  input: UpdateHostUserInput;
}>;


export type UpdateHostUserMutation = { __typename?: 'Mutation', updateHostUser: { __typename: 'HostUser' } };

export type CreateInstitutionMutationVariables = Exact<{
  input: CreateInstitutionInput;
}>;


export type CreateInstitutionMutation = { __typename?: 'Mutation', createInstitution: { __typename?: 'Institution', id: number } };

export type InstitutionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type InstitutionQuery = { __typename?: 'Query', institution: { __typename?: 'Institution', id: number, name: string, domains?: Array<string> | null, isAllowedStudentsLogin: boolean, maxStudents: number, url?: string | null, isPublished: boolean, isAllowedRegisterByInstitutionalEmail: boolean, isAllowedRegisterByStudentEmail: boolean, allowedStudentsEmails?: Array<{ __typename?: 'AllowedStudentEmail', email: string }> | null } };

export type ListInstitutionsQueryVariables = Exact<{
  isPublished?: InputMaybe<Scalars['Boolean']>;
  project?: InputMaybe<Project>;
}>;


export type ListInstitutionsQuery = { __typename?: 'Query', institutions: Array<{ __typename?: 'Institution', id: number, name: string }> };

export type RemoveInstitutionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveInstitutionMutation = { __typename?: 'Mutation', removeInstitution?: { __typename: 'Institution' } | null };

export type UpdateInstitutionMutationVariables = Exact<{
  input: UpdateInstitutionInput;
}>;


export type UpdateInstitutionMutation = { __typename?: 'Mutation', updateInstitution: { __typename?: 'Institution', id: number } };

export type ListItinerariesQueryVariables = Exact<{
  project?: InputMaybe<Project>;
}>;


export type ListItinerariesQuery = { __typename?: 'Query', itineraries: Array<{ __typename?: 'Itinerary', id: number, name: string }> };

export type CreateJitsiJwtMutationVariables = Exact<{
  input: CreateJwtJitsiInput;
}>;


export type CreateJitsiJwtMutation = { __typename?: 'Mutation', createJWTJitsi: string };

export type JitsiDestroyRoomMutationVariables = Exact<{
  input: JitsiDestroyRoom;
}>;


export type JitsiDestroyRoomMutation = { __typename?: 'Mutation', jitsiDestroyRoom: string };

export type AuthLayersMutationVariables = Exact<{
  args: AuthLayersInput;
}>;


export type AuthLayersMutation = { __typename?: 'Mutation', authLayers: { __typename?: 'AuthLogin', accessToken: string } };

export type CountNotificationsQueryVariables = Exact<{
  input?: InputMaybe<FilterNotificationInput>;
}>;


export type CountNotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename: 'Notification' }> };

export type ListNotificationsQueryVariables = Exact<{
  input?: InputMaybe<FilterNotificationInput>;
}>;


export type ListNotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: number, createdAt: any, title: string, text: string, url?: string | null, readed: boolean }> };

export type UpdateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;


export type UpdateNotificationMutation = { __typename?: 'Mutation', updateNotification: { __typename: 'Notification' } };

export type ListProfessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListProfessionsQuery = { __typename?: 'Query', professions: Array<{ __typename?: 'Profession', id: number, name: string }> };

export type CreateScheduleMutationVariables = Exact<{
  input: CreateScheduleInput;
}>;


export type CreateScheduleMutation = { __typename?: 'Mutation', createSchedule: { __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, limit: number } };

export type FinishScheduleMutationVariables = Exact<{
  input: GraphQlRelationInput;
}>;


export type FinishScheduleMutation = { __typename?: 'Mutation', finishSchedule: boolean };

export type ListSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSchedulesQuery = { __typename?: 'Query', schedules: Array<{ __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, limit: number, occurredEndDate?: any | null, experience: { __typename?: 'Experience', id: number, title: string, imageSrcIndex: number, imageSrcs: Array<string>, subtitle: string }, hostUser: { __typename?: 'HostUser', id: number } }> };

export type RemoveScheduleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveScheduleMutation = { __typename?: 'Mutation', removeSchedule: { __typename: 'Schedule' } };

export type ScheduleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ScheduleQuery = { __typename?: 'Query', schedule: { __typename?: 'Schedule', id: number, startDate: any, endDate: any, videoURL: string, limit: number, hostUser: { __typename?: 'HostUser', id: number }, experience: { __typename?: 'Experience', id: number, profession: { __typename?: 'Profession', id: number } } } };

export type UpdateScheduleMutationVariables = Exact<{
  input: UpdateScheduleInput;
}>;


export type UpdateScheduleMutation = { __typename?: 'Mutation', updateSchedule: { __typename?: 'Schedule', id: number, startDate: any, endDate: any } };

export type ListSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'Setting', id: number, name: string, value: string }> };

export type CreateStudentUserForSubscriptionMutationVariables = Exact<{
  input: CreateStudentUserForSubscriptionInput;
}>;


export type CreateStudentUserForSubscriptionMutation = { __typename?: 'Mutation', createStudentUserForSubscription: { __typename: 'StudentUser' } };

export type CreateStudentUserPortugalMutationVariables = Exact<{
  input: CreateStudentUserPortugalInput;
}>;


export type CreateStudentUserPortugalMutation = { __typename?: 'Mutation', createStudentUserPortugal: { __typename: 'StudentUser' } };

export type CurrentStudentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentStudentUserQuery = { __typename?: 'Query', currentStudentUser: { __typename?: 'StudentUser', id: number, number?: string | null, cep?: string | null, ethnicity: StudentUserEthnicity, facebook?: string | null, linkedin?: string | null, instagram?: string | null, familyIncome: StudentUserFamilyIncome, cpf: string, birthDate?: any | null, hoursExperienced: number, state: string, city: string, neighborhood: string, telephone: string, educationLevel: StudentUserEducationLevel, street?: string | null, institution: { __typename?: 'Institution', id: number }, itineraries: Array<{ __typename?: 'Itinerary', id: number, name: string }>, user: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean, isActive: boolean }, profession?: { __typename?: 'Profession', id: number, name: string } | null } };

export type ListStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListStudentsQuery = { __typename?: 'Query', studentUsers: Array<{ __typename?: 'StudentUser', id: number, user: { __typename?: 'User', name: string } }> };

export type RemoveStudentMutationVariables = Exact<{
  input: DeleteStudentUserInput;
}>;


export type RemoveStudentMutation = { __typename?: 'Mutation', removeStudentUser: { __typename: 'StudentUser' } };

export type StudentUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StudentUserQuery = { __typename?: 'Query', studentUser: { __typename?: 'StudentUser', id: number, number?: string | null, cep?: string | null, ethnicity: StudentUserEthnicity, facebook?: string | null, linkedin?: string | null, instagram?: string | null, familyIncome: StudentUserFamilyIncome, cpf: string, birthDate?: any | null, hoursExperienced: number, state: string, city: string, neighborhood: string, telephone: string, educationLevel: StudentUserEducationLevel, street?: string | null, institution: { __typename?: 'Institution', id: number }, itineraries: Array<{ __typename?: 'Itinerary', id: number, name: string }>, user: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean, isActive: boolean } } };

export type UpdateStudentUserMutationVariables = Exact<{
  input: UpdateStudentUserInput;
}>;


export type UpdateStudentUserMutation = { __typename?: 'Mutation', updateStudentUser: { __typename: 'StudentUser' } };

export type CreateTicketMutationVariables = Exact<{
  input: CreateTicketInput;
}>;


export type CreateTicketMutation = { __typename?: 'Mutation', createTicket: { __typename?: 'CreateTicketOutput', success: boolean } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: number, avatarSrc?: string | null, name: string, email: string, role: UserRole, gender: UserGender, isEmailVerified: boolean, studentUser?: { __typename?: 'StudentUser', educationLevel: StudentUserEducationLevel, id: number, cpf: string, institution: { __typename?: 'Institution', url?: string | null } } | null, hostUser?: { __typename?: 'HostUser', id: number } | null, integration?: { __typename?: 'Integration', name: string } | null } };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeUser: { __typename: 'User' } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number } };

export type CreateOrUpdateVocationalTestResultMutationVariables = Exact<{
  input: VocationalTestInput;
}>;


export type CreateOrUpdateVocationalTestResultMutation = { __typename?: 'Mutation', createOrUpdateVocationalTestResult: { __typename?: 'VocationalTest', distResultJson: string } };

export type VocationalTestResultQueryVariables = Exact<{ [key: string]: never; }>;


export type VocationalTestResultQuery = { __typename?: 'Query', vocationalTestResult?: { __typename?: 'VocationalTest', distResultJson: string } | null };

export const LoginUserDocument = gql`
    mutation LoginUser($input: LoginUserInput!) {
  loginUserSerenPassPortugal(loginUserInput: $input) {
    accessToken
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginUserGQL extends Apollo.Mutation<LoginUserMutation, LoginUserMutationVariables> {
    document = LoginUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RequestPasswordRecoveryDocument = gql`
    mutation RequestPasswordRecovery($input: RequestPasswordRecoveryInput!) {
  requestPasswordRecovery(requestPasswordRecoveryInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RequestPasswordRecoveryGQL extends Apollo.Mutation<RequestPasswordRecoveryMutation, RequestPasswordRecoveryMutationVariables> {
    document = RequestPasswordRecoveryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveMaterialDocument = gql`
    mutation RemoveMaterial($experienceId: Int!) {
  removeMaterial(experienceId: $experienceId) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveMaterialGQL extends Apollo.Mutation<RemoveMaterialMutation, RemoveMaterialMutationVariables> {
    document = RemoveMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadMaterialDocument = gql`
    mutation UploadMaterial($file: Upload!, $experienceId: Int!) {
  uploadMaterial(file: $file, experienceId: $experienceId) {
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadMaterialGQL extends Apollo.Mutation<UploadMaterialMutation, UploadMaterialMutationVariables> {
    document = UploadMaterialDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadImageDocument = gql`
    mutation UploadImage($file: Upload!) {
  uploadImage(file: $file) {
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadImageGQL extends Apollo.Mutation<UploadImageMutation, UploadImageMutationVariables> {
    document = UploadImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadUserAvatarDocument = gql`
    mutation UploadUserAvatar($file: Upload!, $userId: Int) {
  uploadUserAvatar(file: $file, userId: $userId) {
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadUserAvatarGQL extends Apollo.Mutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables> {
    document = UploadUserAvatarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEnrollmentRecordedDocument = gql`
    mutation CreateEnrollmentRecorded($input: CreateEnrollmentRecordedInput!) {
  createEnrollmentRecorded(createEnrollmentRecordedInput: $input) {
    id
    rating
    studentUser {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEnrollmentRecordedGQL extends Apollo.Mutation<CreateEnrollmentRecordedMutation, CreateEnrollmentRecordedMutationVariables> {
    document = CreateEnrollmentRecordedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEnrollmentDocument = gql`
    mutation CreateEnrollment($input: CreateEnrollmentInput!) {
  createEnrollment(createEnrollmentInput: $input) {
    id
    rating
    studentUser {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEnrollmentGQL extends Apollo.Mutation<CreateEnrollmentMutation, CreateEnrollmentMutationVariables> {
    document = CreateEnrollmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnrollmentDocument = gql`
    query Enrollment($id: Int!) {
  enrollment(id: $id) {
    id
    createdAt
    rating
    ratingComment
    schedule {
      experience {
        title
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnrollmentGQL extends Apollo.Query<EnrollmentQuery, EnrollmentQueryVariables> {
    document = EnrollmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListEnrollmentsDocument = gql`
    query ListEnrollments($filters: FilterEnrollmentInput) {
  enrollments(filters: $filters) {
    id
    wasPresent
    viewType
    schedule {
      id
      startDate
      endDate
      videoURL
      experience {
        id
        title
        subtitle
        imageSrcs
        imageSrcIndex
        videoUrl
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListEnrollmentsGQL extends Apollo.Query<ListEnrollmentsQuery, ListEnrollmentsQueryVariables> {
    document = ListEnrollmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveEnrollmentDocument = gql`
    mutation RemoveEnrollment($id: Int!) {
  removeEnrollment(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveEnrollmentGQL extends Apollo.Mutation<RemoveEnrollmentMutation, RemoveEnrollmentMutationVariables> {
    document = RemoveEnrollmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEnrollmentDocument = gql`
    mutation UpdateEnrollment($input: UpdateEnrollmentInput!) {
  updateEnrollment(updateEnrollmentInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEnrollmentGQL extends Apollo.Mutation<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables> {
    document = UpdateEnrollmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateExperienceDocument = gql`
    mutation CreateExperience($input: CreateExperienceInput!) {
  createExperience(createExperienceInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateExperienceGQL extends Apollo.Mutation<CreateExperienceMutation, CreateExperienceMutationVariables> {
    document = CreateExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExperienceFindCollectedDocument = gql`
    query ExperienceFindCollected($id: Int!) {
  findCollected(id: $id) {
    id
    title
    subtitle
    imageSrcs
    imageSrcIndex
    description
    tags
    material
    videoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExperienceFindCollectedGQL extends Apollo.Query<ExperienceFindCollectedQuery, ExperienceFindCollectedQueryVariables> {
    document = ExperienceFindCollectedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExperienceWithoutEnrollmentsDocument = gql`
    query ExperienceWithoutEnrollments($id: Int!, $filterSchedule: FilterExperienceSchedulesInput!) {
  experience(id: $id) {
    id
    title
    subtitle
    imageSrcs
    isHighlighted
    imageSrcIndex
    description
    rating {
      value
      count
    }
    profession {
      id
      name
    }
    itineraries {
      id
      name
    }
    schedules(filters: $filterSchedule) {
      id
      startDate
      endDate
      videoURL
      occurredEndDate
      hostUser {
        id
        user {
          name
          avatarSrc
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExperienceWithoutEnrollmentsGQL extends Apollo.Query<ExperienceWithoutEnrollmentsQuery, ExperienceWithoutEnrollmentsQueryVariables> {
    document = ExperienceWithoutEnrollmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExperienceDocument = gql`
    query Experience($id: Int!, $filterSchedule: FilterExperienceSchedulesInput!) {
  experience(id: $id) {
    id
    title
    subtitle
    imageSrcs
    isHighlighted
    imageSrcIndex
    description
    tags
    jobScore
    averageSalary
    material
    videoUrl
    rating {
      value
      count
    }
    profession {
      id
      name
    }
    itineraries {
      id
      name
    }
    schedules(filters: $filterSchedule) {
      id
      startDate
      endDate
      videoURL
      occurredEndDate
      enrollments {
        id
        rating
        studentUser {
          id
        }
      }
      hostUser {
        id
        bio
        user {
          name
          avatarSrc
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExperienceGQL extends Apollo.Query<ExperienceQuery, ExperienceQueryVariables> {
    document = ExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListExperiencesDocument = gql`
    query ListExperiences($filters: [FilterExperienceInput!], $orders: OrderExperienceInput) {
  experiences(filters: $filters, orders: $orders) {
    id
    title
    subtitle
    imageSrcs
    isHighlighted
    imageSrcIndex
    description
    jobScore
    averageSalary
    videoUrl
    itineraries {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListExperiencesGQL extends Apollo.Query<ListExperiencesQuery, ListExperiencesQueryVariables> {
    document = ListExperiencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RaioXDocument = gql`
    query RaioX {
  raiox {
    youMayAlsoLike {
      id
      title
    }
    experiencesLived {
      schedule {
        startDate
        hostUser {
          user {
            name
          }
        }
        experience {
          profession {
            id
            name
          }
          id
          title
          itineraries {
            name
          }
        }
      }
      viewType
      rating
      createdAt
    }
    latentAreas
    raioXMinEnrollment
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RaioXGQL extends Apollo.Query<RaioXQuery, RaioXQueryVariables> {
    document = RaioXDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RecommendedExperienceDocument = gql`
    query RecommendedExperience {
  recommendedExperiences {
    id
    title
    subtitle
    imageSrcs
    imageSrcIndex
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecommendedExperienceGQL extends Apollo.Query<RecommendedExperienceQuery, RecommendedExperienceQueryVariables> {
    document = RecommendedExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveExperienceDocument = gql`
    mutation RemoveExperience($id: Int!) {
  removeExperience(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveExperienceGQL extends Apollo.Mutation<RemoveExperienceMutation, RemoveExperienceMutationVariables> {
    document = RemoveExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateExperienceDocument = gql`
    mutation UpdateExperience($input: UpdateExperienceInput!) {
  updateExperience(updateExperienceInput: $input) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateExperienceGQL extends Apollo.Mutation<UpdateExperienceMutation, UpdateExperienceMutationVariables> {
    document = UpdateExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateHostUserDocument = gql`
    mutation CreateHostUser($input: CreateHostUserInput!) {
  createHostUser(createHostUserInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHostUserGQL extends Apollo.Mutation<CreateHostUserMutation, CreateHostUserMutationVariables> {
    document = CreateHostUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentHostSchedulesDocument = gql`
    query CurrentHostSchedules {
  currentHostUser {
    schedules {
      id
      startDate
      endDate
      videoURL
      experience {
        id
        title
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentHostSchedulesGQL extends Apollo.Query<CurrentHostSchedulesQuery, CurrentHostSchedulesQueryVariables> {
    document = CurrentHostSchedulesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentHostUserDocument = gql`
    query CurrentHostUser {
  currentHostUser {
    id
    number
    cep
    facebook
    linkedin
    instagram
    cpf
    birthDate
    state
    city
    neighborhood
    telephone
    educationLevel
    street
    itinerary {
      id
    }
    educationalInstitution
    isVolunteer
    isApproved
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
    }
    bio
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentHostUserGQL extends Apollo.Query<CurrentHostUserQuery, CurrentHostUserQueryVariables> {
    document = CurrentHostUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HostUserDocument = gql`
    query HostUser($id: Int!) {
  hostUser(id: $id) {
    id
    number
    cep
    facebook
    linkedin
    instagram
    cpf
    birthDate
    state
    city
    neighborhood
    telephone
    educationLevel
    street
    itinerary {
      id
    }
    educationalInstitution
    isVolunteer
    isApproved
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
    }
    bio
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HostUserGQL extends Apollo.Query<HostUserQuery, HostUserQueryVariables> {
    document = HostUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListHostsDocument = gql`
    query ListHosts($orders: OrderHostInput) {
  hostUsers(orders: $orders) {
    id
    user {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListHostsGQL extends Apollo.Query<ListHostsQuery, ListHostsQueryVariables> {
    document = ListHostsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveHostDocument = gql`
    mutation RemoveHost($id: Int!) {
  removeHostUser(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveHostGQL extends Apollo.Mutation<RemoveHostMutation, RemoveHostMutationVariables> {
    document = RemoveHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHostUserDocument = gql`
    mutation UpdateHostUser($input: UpdateHostUserInput!) {
  updateHostUser(updateHostUserInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHostUserGQL extends Apollo.Mutation<UpdateHostUserMutation, UpdateHostUserMutationVariables> {
    document = UpdateHostUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateInstitutionDocument = gql`
    mutation CreateInstitution($input: CreateInstitutionInput!) {
  createInstitution(createInstitutionInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInstitutionGQL extends Apollo.Mutation<CreateInstitutionMutation, CreateInstitutionMutationVariables> {
    document = CreateInstitutionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InstitutionDocument = gql`
    query Institution($id: Int!) {
  institution(id: $id) {
    id
    name
    domains
    isAllowedStudentsLogin
    maxStudents
    allowedStudentsEmails {
      email
    }
    url
    isPublished
    isAllowedRegisterByInstitutionalEmail
    isAllowedRegisterByStudentEmail
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InstitutionGQL extends Apollo.Query<InstitutionQuery, InstitutionQueryVariables> {
    document = InstitutionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListInstitutionsDocument = gql`
    query ListInstitutions($isPublished: Boolean, $project: Project) {
  institutions(isPublished: $isPublished, project: $project) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListInstitutionsGQL extends Apollo.Query<ListInstitutionsQuery, ListInstitutionsQueryVariables> {
    document = ListInstitutionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveInstitutionDocument = gql`
    mutation removeInstitution($id: Int!) {
  removeInstitution(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveInstitutionGQL extends Apollo.Mutation<RemoveInstitutionMutation, RemoveInstitutionMutationVariables> {
    document = RemoveInstitutionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInstitutionDocument = gql`
    mutation UpdateInstitution($input: UpdateInstitutionInput!) {
  updateInstitution(updateInstitutionInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInstitutionGQL extends Apollo.Mutation<UpdateInstitutionMutation, UpdateInstitutionMutationVariables> {
    document = UpdateInstitutionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListItinerariesDocument = gql`
    query ListItineraries($project: Project) {
  itineraries(project: $project) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListItinerariesGQL extends Apollo.Query<ListItinerariesQuery, ListItinerariesQueryVariables> {
    document = ListItinerariesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateJitsiJwtDocument = gql`
    mutation CreateJitsiJWT($input: CreateJWTJitsiInput!) {
  createJWTJitsi(createJWTJitsiInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateJitsiJwtGQL extends Apollo.Mutation<CreateJitsiJwtMutation, CreateJitsiJwtMutationVariables> {
    document = CreateJitsiJwtDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const JitsiDestroyRoomDocument = gql`
    mutation JitsiDestroyRoom($input: JitsiDestroyRoom!) {
  jitsiDestroyRoom(jitsiDestroyRoom: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class JitsiDestroyRoomGQL extends Apollo.Mutation<JitsiDestroyRoomMutation, JitsiDestroyRoomMutationVariables> {
    document = JitsiDestroyRoomDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuthLayersDocument = gql`
    mutation AuthLayers($args: AuthLayersInput!) {
  authLayers(args: $args) {
    accessToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthLayersGQL extends Apollo.Mutation<AuthLayersMutation, AuthLayersMutationVariables> {
    document = AuthLayersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CountNotificationsDocument = gql`
    query CountNotifications($input: FilterNotificationInput) {
  notifications(filters: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CountNotificationsGQL extends Apollo.Query<CountNotificationsQuery, CountNotificationsQueryVariables> {
    document = CountNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListNotificationsDocument = gql`
    query ListNotifications($input: FilterNotificationInput) {
  notifications(filters: $input) {
    id
    createdAt
    title
    text
    url
    readed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListNotificationsGQL extends Apollo.Query<ListNotificationsQuery, ListNotificationsQueryVariables> {
    document = ListNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($input: UpdateNotificationInput!) {
  updateNotification(updateNotificationInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateNotificationGQL extends Apollo.Mutation<UpdateNotificationMutation, UpdateNotificationMutationVariables> {
    document = UpdateNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListProfessionsDocument = gql`
    query ListProfessions {
  professions {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListProfessionsGQL extends Apollo.Query<ListProfessionsQuery, ListProfessionsQueryVariables> {
    document = ListProfessionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateScheduleDocument = gql`
    mutation CreateSchedule($input: CreateScheduleInput!) {
  createSchedule(createScheduleInput: $input) {
    id
    startDate
    endDate
    videoURL
    limit
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateScheduleGQL extends Apollo.Mutation<CreateScheduleMutation, CreateScheduleMutationVariables> {
    document = CreateScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinishScheduleDocument = gql`
    mutation FinishSchedule($input: GraphQLRelationInput!) {
  finishSchedule(schedule: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinishScheduleGQL extends Apollo.Mutation<FinishScheduleMutation, FinishScheduleMutationVariables> {
    document = FinishScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSchedulesDocument = gql`
    query ListSchedules {
  schedules {
    id
    startDate
    endDate
    videoURL
    limit
    occurredEndDate
    experience {
      id
      title
      imageSrcIndex
      imageSrcs
      subtitle
    }
    hostUser {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSchedulesGQL extends Apollo.Query<ListSchedulesQuery, ListSchedulesQueryVariables> {
    document = ListSchedulesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveScheduleDocument = gql`
    mutation RemoveSchedule($id: Int!) {
  removeSchedule(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveScheduleGQL extends Apollo.Mutation<RemoveScheduleMutation, RemoveScheduleMutationVariables> {
    document = RemoveScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScheduleDocument = gql`
    query Schedule($id: Int!) {
  schedule(id: $id) {
    id
    startDate
    endDate
    videoURL
    limit
    hostUser {
      id
    }
    experience {
      id
      profession {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduleGQL extends Apollo.Query<ScheduleQuery, ScheduleQueryVariables> {
    document = ScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateScheduleDocument = gql`
    mutation UpdateSchedule($input: UpdateScheduleInput!) {
  updateSchedule(updateScheduleInput: $input) {
    id
    startDate
    endDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateScheduleGQL extends Apollo.Mutation<UpdateScheduleMutation, UpdateScheduleMutationVariables> {
    document = UpdateScheduleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListSettingsDocument = gql`
    query ListSettings {
  settings {
    id
    name
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListSettingsGQL extends Apollo.Query<ListSettingsQuery, ListSettingsQueryVariables> {
    document = ListSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStudentUserForSubscriptionDocument = gql`
    mutation CreateStudentUserForSubscription($input: CreateStudentUserForSubscriptionInput!) {
  createStudentUserForSubscription(createStudentUserForSubscriptionInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStudentUserForSubscriptionGQL extends Apollo.Mutation<CreateStudentUserForSubscriptionMutation, CreateStudentUserForSubscriptionMutationVariables> {
    document = CreateStudentUserForSubscriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStudentUserPortugalDocument = gql`
    mutation CreateStudentUserPortugal($input: CreateStudentUserPortugalInput!) {
  createStudentUserPortugal(createStudentUserPortugalInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStudentUserPortugalGQL extends Apollo.Mutation<CreateStudentUserPortugalMutation, CreateStudentUserPortugalMutationVariables> {
    document = CreateStudentUserPortugalDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentStudentUserDocument = gql`
    query CurrentStudentUser {
  currentStudentUser {
    id
    number
    cep
    ethnicity
    facebook
    linkedin
    instagram
    familyIncome
    cpf
    birthDate
    hoursExperienced
    institution {
      id
    }
    state
    city
    neighborhood
    telephone
    educationLevel
    itineraries {
      id
      name
    }
    street
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
      isActive
    }
    profession {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentStudentUserGQL extends Apollo.Query<CurrentStudentUserQuery, CurrentStudentUserQueryVariables> {
    document = CurrentStudentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListStudentsDocument = gql`
    query ListStudents {
  studentUsers {
    id
    user {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListStudentsGQL extends Apollo.Query<ListStudentsQuery, ListStudentsQueryVariables> {
    document = ListStudentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveStudentDocument = gql`
    mutation RemoveStudent($input: DeleteStudentUserInput!) {
  removeStudentUser(deleteStudentUserInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveStudentGQL extends Apollo.Mutation<RemoveStudentMutation, RemoveStudentMutationVariables> {
    document = RemoveStudentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StudentUserDocument = gql`
    query StudentUser($id: Int!) {
  studentUser(id: $id) {
    id
    number
    cep
    ethnicity
    facebook
    linkedin
    instagram
    familyIncome
    cpf
    birthDate
    hoursExperienced
    institution {
      id
    }
    state
    city
    neighborhood
    telephone
    educationLevel
    itineraries {
      id
      name
    }
    street
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
    }
    id
    number
    cep
    ethnicity
    facebook
    linkedin
    instagram
    familyIncome
    cpf
    birthDate
    institution {
      id
    }
    state
    city
    neighborhood
    telephone
    educationLevel
    itineraries {
      id
      name
    }
    street
    user {
      id
      avatarSrc
      name
      email
      role
      gender
      isEmailVerified
      isActive
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StudentUserGQL extends Apollo.Query<StudentUserQuery, StudentUserQueryVariables> {
    document = StudentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStudentUserDocument = gql`
    mutation UpdateStudentUser($input: UpdateStudentUserInput!) {
  updateStudentUser(updateStudentUserInput: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStudentUserGQL extends Apollo.Mutation<UpdateStudentUserMutation, UpdateStudentUserMutationVariables> {
    document = UpdateStudentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTicketDocument = gql`
    mutation CreateTicket($input: CreateTicketInput!) {
  createTicket(createTicketInput: $input) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTicketGQL extends Apollo.Mutation<CreateTicketMutation, CreateTicketMutationVariables> {
    document = CreateTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    avatarSrc
    name
    email
    role
    gender
    isEmailVerified
    studentUser {
      educationLevel
      id
      cpf
      institution {
        url
      }
    }
    hostUser {
      id
    }
    integration {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: Int!) {
  removeUser(id: $id) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserGQL extends Apollo.Mutation<RemoveUserMutation, RemoveUserMutationVariables> {
    document = RemoveUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(updateUserInput: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrUpdateVocationalTestResultDocument = gql`
    mutation CreateOrUpdateVocationalTestResult($input: VocationalTestInput!) {
  createOrUpdateVocationalTestResult(input: $input) {
    distResultJson
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrUpdateVocationalTestResultGQL extends Apollo.Mutation<CreateOrUpdateVocationalTestResultMutation, CreateOrUpdateVocationalTestResultMutationVariables> {
    document = CreateOrUpdateVocationalTestResultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VocationalTestResultDocument = gql`
    query VocationalTestResult {
  vocationalTestResult {
    distResultJson
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VocationalTestResultGQL extends Apollo.Query<VocationalTestResultQuery, VocationalTestResultQueryVariables> {
    document = VocationalTestResultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    