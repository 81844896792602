import { FormGroup, ValidationErrors } from "@angular/forms";

interface iStringMatchParams {
  controlName: string;
  matchString: string;
}

// VERIFICA SE VALOR DO CONTROL É IGUAL AO VALOR DA STRING MATCHVALUE
export function validateStringMath(data: iStringMatchParams): ValidationErrors | null {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[data.controlName];

    if (control.value.toLowerCase() !== data.matchString.toLowerCase()) {
      control.setErrors({ stringMatch: true });
    } else {
      control.setErrors(null);
    }
  };
}
