import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CurrentUserGQL, CurrentUserQuery, User } from 'src/generated/graphql';

export interface CurrentUserSessionView {
  currentUser: CurrentUserQuery['currentUser'];
}
export interface UpdateCurrentUserSessionView {
  currentUser: Partial<CurrentUserQuery['currentUser']>;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private $currentUserView: BehaviorSubject<CurrentUserSessionView | null> =
    new BehaviorSubject(null);
  currentUserView$ = this.$currentUserView.asObservable();

  // currentStudentUser: CurrentStudentUserQuery['currentStudentUser'];
  socialURLs = {
    linkedIn: `https://www.linkedin.com/in/`,
    facebook: `https://www.facebook.com/`,
    instagram: `https://www.instagram.com/`,
  };

  constructor(private currentUserGQL: CurrentUserGQL) {}

  getAvatarSrc(user: Pick<User, 'avatarSrc'>) {
    const src = user?.avatarSrc;

    if (src) return environment.mediaURL + (src ?? '');
    else {
      return '/assets/icons/avatar.svg';
    }
  }

  updateCurrentUser(user: UpdateCurrentUserSessionView | null) {
    if (user === null) {
      this.$currentUserView.next(null);
      return;
    }

    const current = this.getUser();

    this.$currentUserView.next({
      currentUser: {
        ...current?.currentUser!,
        ...user.currentUser,
      },
    });
  }

  async autoUpdateCurrentUser() {
    try {
      const result = await firstValueFrom(this.currentUserGQL.fetch());

      this.updateCurrentUser({
        currentUser: result?.data.currentUser,
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getUser() {
    return this.$currentUserView.getValue();
  }

  checkIsIntegrationRegisterIncomplete() {
    const user = this.getUser()?.currentUser;

    //CASO ESPECIAL PARA INTEGRAÇÃO DA EDUCACIONAL QUE NÃO PRECISA COMPLETAR O CADASTRO
    if (user?.integration?.name === 'Educacional') return false;

    const isIntegrationUser = this.checkIsIntegrationUser();
    const isCpfEmpty = user?.studentUser?.cpf === '';

    if (isCpfEmpty && isIntegrationUser) return true;

    return false;
  }

  checkIsIntegrationUser() {
    const user = this.getUser()?.currentUser;
    return typeof user?.integration?.name !== 'undefined';
  }

  hasUser(): boolean {
    let hasUser: boolean;
    if (this.$currentUserView.getValue() !== null) hasUser = true;
    else hasUser = false;

    return hasUser;
  }
}
