import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { RaioXQuery, UserRole } from 'src/generated/graphql';
import { environment } from '../../../../environments/environment';
import { ExperienceService } from '../../../experience/services/experience/experience.service';
import { UserService } from '../../../user/services/user/user.service';
import { LayoutService } from '../../services/layout/layout.service';
import { RouterLayoutService } from '../../services/router/router-layout.service';
import { TranslationsLayoutService } from '../../services/translations/translations-layout.service';
import { CORE_SIDE_MENU_ROUTE_NAME } from '../../types/enums/core-side-menu-route-name.enum';

@Component({
  selector: 'core-side-menu',
  templateUrl: './core-side-menu.component.html',
  styleUrls: ['./core-side-menu.component.scss'],
})
export class CoreSideMenuComponent implements OnInit {
  isOpenSideMenu: boolean = false;
  coreSideMenuRouteName = CORE_SIDE_MENU_ROUTE_NAME;
  activeRouter: string;
  userRole = UserRole;
  environment = environment;
  raioX?: RaioXQuery['raiox'];

  constructor(
    public responsivenessObserver: LayoutService,
    public translations: TranslationsLayoutService,
    private router: Router,
    public routerLayoutService: RouterLayoutService,
    private experienceService: ExperienceService,
    private userService: UserService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.activeRouter = event.url;
      }
    });
  }

  ngOnInit() {
    if (this.userService.getUser()?.currentUser.role === UserRole.Student) {
      this.getRaioX();
    }
  }

  toggleSideMenu() {
    this.isOpenSideMenu = !this.isOpenSideMenu;
  }

  navigate(route: string) {
    if (this.responsivenessObserver.isMobile) {
      this.isOpenSideMenu = false;
    }
    this.router.navigate([route]);
  }

  async getRaioX() {
    this.raioX = await this.experienceService.getRaioX();
  }
}
