import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { environment } from 'src/environments/environment';
import { UploadImageGQL } from '../../../../generated/graphql';

@Component({
  selector: 'shared-form-banner-image',
  templateUrl: './shared-form-banner-image.component.html',
  styleUrls: ['./shared-form-banner-image.component.scss'],
})
export class SharedFormBannerImageComponent implements OnInit, AfterViewInit {
  @Input() dataTestId?: string;
  @Input() parentFormGroup: FormGroup;
  @Input() controlBannerName: string;
  @Input() controlHighlighName: string;
  @Input() title: string;
  @Input() helpTextHeader: string;
  @Input() helpTextFooter: string;
  selectedImages: Array<{ url: string; loaded: boolean }> = [];
  highlightedImageIndex: number;

  constructor(
    public translations: TranslationsLayoutService,
    private toastService: ToastService,
    private uploadImageGQL: UploadImageGQL
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.setDefaultValue();
    this.subscribeOnFormValueChange();
  }

  subscribeOnFormValueChange() {
    const control = this.parentFormGroup.controls[this.controlBannerName];

    control.valueChanges.subscribe((value: any) => {
      if (value === null) {
        this.selectedImages = [];
      }
    });
  }

  setDefaultValue() {
    for (const url of this.parentFormGroup.controls[this.controlBannerName]
      .value) {
      this.selectedImages.push({
        url: url,
        loaded: false,
      });
    }

    this.highlightedImageIndex =
      this.parentFormGroup.controls[this.controlHighlighName].value;
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  async changedImage(event: Event) {
    console.log('changedImage');
    let file = (event.target as HTMLInputElement)?.files?.[0];

    // VERIFICA SE EXISTE UM ARQUIVO E SE O ARQUIVO ANTERIOR TERMINOU DE CARREGAR
    if (
      !file ||
      this.selectedImages[this.selectedImages.length - 1]?.loaded === false
    )
      return;

    if (file.size > 15000000) {
      this.toastService.error(
        `Tamanho máximo permitido 15mb, sua imagem tem ${(
          file.size / 1000000
        ).toFixed(1)}Mb`
      );

      return;
    }

    this.selectedImages.push({ url: '', loaded: false });
    const lastIndex = this.selectedImages.length - 1;

    const imageURL = await this.uploadFIle(file);

    this.selectedImages[lastIndex].url = environment.mediaURL + imageURL;

    if (this.selectedImages.length === 1) {
      this.setHighlightedImage(0);
    }

    this.setFormValue();
  }

  setFormValue() {
    let tempSelectedImagesUrl = [];

    for (const selectedImageUrl of this.selectedImages) {
      tempSelectedImagesUrl.push(selectedImageUrl.url);
    }

    this.parentFormGroup.controls[this.controlBannerName].setValue(
      tempSelectedImagesUrl
    );
  }

  async uploadFIle(file: File) {
    const result = await firstValueFrom(
      this.uploadImageGQL.mutate(
        { file: file },
        { context: { useMultipart: true } }
      )
    );
    return result.data?.uploadImage.path;
  }

  deleteImage(index: number) {
    this.selectedImages.splice(index, 1);
    this.setFormValue();
  }

  setHighlightedImage(index: number) {
    this.highlightedImageIndex = index;

    this.parentFormGroup.controls[this.controlHighlighName].setValue(
      this.highlightedImageIndex
    );
  }

  imageLoaded(index: number) {
    console.log('imageLoad');
    this.selectedImages[index].loaded = true;
  }
}
