import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SharedFormSelectOptionModel } from 'src/app/core/types/interfaces/shared-form-select-option.model';

@Component({
  selector: 'shared-form-checklist',
  templateUrl: './shared-form-checklist.component.html',
  styleUrls: ['./shared-form-checklist.component.scss'],
})
export class SharedFormChecklistComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() placeholder: string = '';
  @Input() helpText: string;
  @Input() toolTipText: string;

  @Input() options: Array<SharedFormSelectOptionModel>;
  // get options() {
  //   return this._options;
  // }
  // @Input() set options(options) {
  //   this._options = options;

  //   // if (typeof options !== undefined && options.length >= 1) {
  //   //   const selectedOption = this.getSelectedOption();
  //   //   // console.log('selectedOption', selectedOption);
  //   //   if (selectedOption) this.selectOption(selectedOption);
  //   // }
  // }

  constructor() {}

  ngOnInit() {}

  isChecked(option: SharedFormSelectOptionModel) {
    const control = this.parentFormGroup.controls[this.controlName];
    return control.value.includes(option.code);
  }

  manageList(
    $event: CustomEvent<{ checked: boolean; value: string | number }>
  ) {
    const control = this.parentFormGroup.controls[this.controlName];

    if ($event.detail.checked) {
      const array: Array<string | number> = control.value;
      array.push($event.detail.value);
      control.setValue(array);
    } else {
      const array: Array<string | number> = control.value;

      const index = array.findIndex((element) => {
        return element === $event.detail.value;
      });

      array.splice(index, 1);

      control.setValue(array);
    }
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
