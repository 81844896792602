import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';

@Component({
	selector: 'shared-form-error',
	templateUrl: './shared-form-error.component.html',
	styleUrls: ['./shared-form-error.component.scss'],
})
export class SharedFormErrorComponent implements OnInit {
	@Input() control: AbstractControl;
	@Input() messages: Partial<Record<keyof typeof Validators, string>>;
	@Input() isChangeAllowed: boolean = false;

	constructor(public translations: TranslationsLayoutService) {}

	ngOnInit() {
		this.control.statusChanges.subscribe(() => {
			this.isChangeAllowed = true;
		});
	}

	get errorName(): keyof ValidationErrors {
		if (this.control.errors !== null && this.isChangeAllowed === true)
			return Object.keys(this.control.errors)[0];
		return '';
	}
}
