export enum CORE_SIDE_MENU_ROUTE_NAME {
  HOME = '/experience/home',
  COLLECTED = '/experience/collected',
  HOST_COLLECTED = '/experience/host-collected',
  CREATE = '/experience/create',
  MY_EXPERIENCES = '/user/profile/my-experiences',
  HELP = '/support/common-questions',
  SCHEDULE_LIST_EDIT = '/schedule/list-edit',
  SCHEDULE_LIST = '/schedule/list',
  SCHEDULE_LIST_STUDENT = '/schedule/list-student',
  SCHEDULE_LIST_HOST = '/schedule/list-host',
  LIST_EXPERIENCES = '/experience/list',
  HOST_LIST = '/host/list',
  STUDENT_LIST = '/student/list',
  INSTITUTION_LIST = '/institution/list',
  RAIO_X = '/experience/raio-x',
  BLOG = '/experience/blog',
  VOCACIONAL_TEST = '/teste-vocacional/intro',
  SEREN_DEEP = '/seren-deep/intro',
}
