import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { UserService } from 'src/app/user/services/user/user.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from '../services/layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate, CanLoad {
  token: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private layoutService: LayoutService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.url[0].path === 'update-password') {
      this.layoutService.$isToShowMenus.next(false);
    } else if (route.url[0].path === 'home') {
      this.layoutService.$isToShowMenus.next(true);
    }

    const isToActivate =
      (await this.checkIsLoggedIn()) &&
      (await this.checkIfRegisterIsFull(route, state));
    return isToActivate;
  }

  async canLoad(route: Route, segments: UrlSegment[]) {
    return this.checkIsLoggedIn();
  }

  async checkIfRegisterIsFull(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.userService.checkIsIntegrationRegisterIncomplete()) return true;
    if (this.router.url === '/student/profile') return false;

    if (state.url !== '/student/profile') {
      this.router.navigate(['/student/profile']);
      return false;
    }

    return true;
  }

  private async checkIsLoggedIn(): Promise<boolean> {
    try {
      if (this.hasUserInService() === true) return true;
      if (this.hasTokenInURL() === true) {
        localStorage.setItem(environment.tokenName, this.token);
        // return true;
      }
      if (await this.userService.autoUpdateCurrentUser()) return true;

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  hasUserInService() {
    return this.userService.hasUser();
  }

  hasTokenInURL() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('t') === false) return false;

    const token = urlParams.get('t');

    if (token === null || token === '' || token === undefined) return false;

    this.token = token;

    return true;
  }
}
