import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { ExperienceService } from 'src/app/experience/services/experience/experience.service';

@Component({
  selector: 'shared-form-step-footer',
  templateUrl: './shared-form-step-footer.component.html',
  styleUrls: ['./shared-form-step-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SharedFormStepFooterComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() confirmButtonText: string;
  @Input() confirmIconSrc: string = 'assets/icons/flag.svg';
  @Output() saveEvent = new EventEmitter<void>();

  constructor(
    public translations: TranslationsLayoutService,
    public experienceService: ExperienceService
  ) {}

  ngOnInit() {}

  clear() {
    this.parentFormGroup?.reset();
  }

  save() {
    this.saveEvent.emit();
  }
}
