import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { EnrollmentService } from 'src/app/enrollment/services/enrollment/enrollment.service';
import { SharedModalRateExperienceComponent } from 'src/app/shared/components/modals/shared-modal-rate-experience/shared-modal-rate-experience.component';
import { environment } from 'src/environments/environment';
import {
  EnrollmentQuery,
  FilterOperation,
  ListNotificationsQuery,
} from 'src/generated/graphql';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'notification-pop-over',
  templateUrl: './notification-pop-over.component.html',
  styleUrls: ['./notification-pop-over.component.scss'],
})
export class NotificationPopOverComponent implements OnInit {
  notifications: ListNotificationsQuery['notifications'] = [];
  formattedDateStart: Date;

  constructor(
    public translations: TranslationsLayoutService,
    private notificationService: NotificationService,
    private toastService: ToastService,
    private enrollmentService: EnrollmentService,
    private modalController: ModalController,
    private popOverController: PopoverController
  ) {}

  ngOnInit() {
    this.initialRequest();
  }

  async initialRequest() {
    this.notifications = await this.notificationService.list({
      readed: { value: [false], operation: FilterOperation.Equals },
    });
  }

  async navigate(route: string, notificationId: number, index: number) {
    const [name, id] = route.split('/');
    if (name === 'rating') {
      console.log('tem rating', route);
      // verifica se enrollment já foi avaliado
      const result = await this.enrollmentService.enrollment(parseInt(id));

      if (result.rating) {
        this.toastService.error('Você já avaliou essa experiência');
        return;
      }

      this.popOverController.dismiss();
      this.openModalRateExperience(result, notificationId, index);

      return;
    }

    const domainsToReplace = [
      'serenpro.com.br',
      'staging.serenpro.com.br',
      'serenpass.com.pt',
      'staging.serenpass.com.pt',
      'serenpass.com',
      'staging-front.serenpass.com',
    ];

    const urlObj = new URL(route);
    const match = domainsToReplace.find((domain) => domain == urlObj.hostname);

    if (match) {
      const appURL = new URL(environment.frontUrl);
      urlObj.hostname = appURL.hostname;
      urlObj.port = appURL.port;
    }

    window.location.href = urlObj.toString();
    this.popOverController.dismiss();
  }

  async openModalRateExperience(
    enrollment: EnrollmentQuery['enrollment'],
    notificationId: number,
    index: number
  ) {
    const modal = await this.modalController.create({
      component: SharedModalRateExperienceComponent,
      cssClass: '--shared-modal-structure',
      id: 'sharedModalRateExperience',
      componentProps: {
        id: 'sharedModalRateExperience',
        enrollment: enrollment,
      },
    });

    modal.onDidDismiss().then((res) => {
      if (res?.data?.sended) {
        this.update(notificationId, index);
      }
    });

    await modal.present();
  }

  async update(id: number, index: number) {
    await this.notificationService.update({ id: id, readed: true });
    await this.notificationService.updateViewCount();

    this.notifications.splice(index, 1);

    this.toastService.success('Notificação deletada com sucesso!');
  }
}
