import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AutocompleteTypes } from '@ionic/core';

@Component({
  selector: 'shared-form-input',
  templateUrl: './shared-form-input.component.html',
  styleUrls: ['./shared-form-input.component.scss'],
})
export class SharedFormInputComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() type: 'text' | 'password' | 'number' | 'email' = 'text';
  @Input() title: string;
  @Input() placeholder: string = '';
  @Input() helpText: string;
  @Input() toolTipText: string;

  @Input() autocomplete: AutocompleteTypes = 'on';

  @Output() keyPressed = new EventEmitter<KeyboardEvent>();
  ngOnInit() {}

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
