import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'shared-form-address-input',
  templateUrl: './shared-form-address-input.component.html',
  styleUrls: ['./shared-form-address-input.component.scss'],
})
export class SharedFormAddressInputComponent implements OnInit, AfterViewInit {
  @ViewChild('inputSearch') inputSearch: ElementRef<HTMLInputElement>;
  @Input() parentFormGroup: FormGroup;
  @Input() formGroupName: string;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() helpText: string;
  @Input() type: 'cityAndState' | 'neighborhood' = 'cityAndState';
  @Input() state = '';
  @Input() city = '';

  @Output() optionSelected = new EventEmitter();

  get local(): AbstractControl {
    return (this.parentFormGroup.controls[this.formGroupName] as FormGroup)
      .controls?.local;
  }

  disableInputAddressChanges = false;

  addresses: Array<google.maps.places.AutocompletePrediction> = [];
  googleMapsTypes: string[] = [];
  googleMapsFields: string[];

  lettersRegex = `a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð`;
  filterRegExp: RegExp;

  private googleAutocompleteService =
    new google.maps.places.AutocompleteService();

  // private googleGeocoder = new google.maps.Geocoder();

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    this.handleType();
  }

  handleType() {
    switch (this.type) {
      case 'cityAndState':
        this.googleMapsTypes = ['(cities)'];
        this.filterRegExp = new RegExp(
          `^[${this.lettersRegex} ]+, [${this.lettersRegex} ]+, Portugal`
        );
        break;
      case 'neighborhood':
        this.googleMapsTypes = ['(regions)'];
        this.googleMapsFields = ['sublocality', 'locality'];
        this.filterRegExp = new RegExp(
          `^[${this.lettersRegex} ]+, [${this.lettersRegex} ]+ \- [A-Z]{2}, Portugal`
        );
        break;
      default:
    }
  }

  ngAfterViewInit() {}

  isControlRequired(group: FormGroup): boolean {
    if (typeof group?.controls?.local === 'undefined') {
      return false;
    }
    const control = group.controls.local;

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  async searchAddress() {
    if (this.disableInputAddressChanges) return;

    this.addresses = [];

    if (!this.local?.value) return;

    let input = '';

    if (this.type === 'cityAndState') {
      // input = `${this.state} ${this.local.value}`;
      input = `${this.local.value}`;
    } else if (this.type === 'neighborhood') {
      // input = `${this.state} ${this.city} ${this.local.value}`;
      input = `${this.state} ${this.city} ${this.local.value}`;
    }

    // console.log('cityAndState', input);
    const request: google.maps.places.AutocompletionRequest = {
      input,
      componentRestrictions: { country: 'pt' },
      types: this.googleMapsTypes,
    };

    const autocompleteResponse: google.maps.places.AutocompleteResponse =
      await this.googleAutocompleteService.getPlacePredictions(request);

    console.log('autocompleteResponse', autocompleteResponse);

    const predictions = autocompleteResponse.predictions;

    // console.log(predictions);
    for (const prediction of predictions) {
      // console.log(prediction.description);
      // console.log(prediction.description.match(this.filterRegExp));

      // if (prediction.description.match(this.filterRegExp) === null) continue;
      console.log('predication filtrado', prediction.description);

      const types = prediction.types;
      if (this.type === 'neighborhood') {
        const found = types.some((r) => this.googleMapsFields.indexOf(r) >= 0);
        // console.log(found);
        if (!found) continue;
      }

      let description = prediction.description;
      // description = prediction.description.split(',')[0];

      // if (this.type === 'neighborhood')
      //   description = prediction.description.split(',')[0];
      // else if (this.type === 'cityAndState')
      description = prediction.description.replace(', Portugal', '');
      // prediction.description = description;

      this.addresses.push({ ...prediction, description });
    }
  }

  getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.inputSearch.nativeElement,
      {
        componentRestrictions: { country: 'PT' },
        types: this.googleMapsTypes,
        fields: this.googleMapsFields,
      }
    );

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        console.log('place', place);

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
      });
    });
  }

  async setAddress(address: google.maps.places.AutocompletePrediction) {
    this.disableInputAddressChanges = true;

    // await this.setLatitudeAndLongitude(address.place_id);

    this.local?.setValue(address.description);

    this.addresses = [];

    this.optionSelected.emit();

    setTimeout(() => {
      this.disableInputAddressChanges = false;
    }, 1000);
  }

  //remove for now
  // async setLatitudeAndLongitude(placeId: string) {
  //   await this.googleGeocoder.geocode(
  //     {
  //       placeId: placeId,
  //     },
  //     (res) => {
  //       if (!res) return;

  //       const controls = (
  //         this.parentFormGroup.controls[this.formGroupName] as FormGroup
  //       ).controls;

  //       const latitude = controls['latitude'];
  //       const longitude = controls['longitude'];

  //       latitude.setValue(res[0].geometry.location.lat());

  //       longitude.setValue(res[0].geometry.location.lng());
  //     }
  //   );
  // }
}
