import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Papa } from 'ngx-papaparse';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';

@Component({
  selector: 'shared-form-csv-list-editable',
  templateUrl: './shared-form-csv-list-editable.component.html',
  styleUrls: ['./shared-form-csv-list-editable.component.scss'],
})
export class SharedFormCSVListEditableComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() placeholder: string;
  @Input() helpText: string;
  @Input() dataTestId?: string;
  formGroup = new FormGroup({
    newItem: new FormControl('', {
      validators: [Validators.required, Validators.email],
      nonNullable: true,
    }),
    list: new FormArray<FormControl<string>>([]),
  });

  constructor(
    public translations: TranslationsLayoutService,
    private papa: Papa
  ) {}

  ngOnInit() {
    (
      this.parentFormGroup.controls[this.controlName].value as Array<string>
    ).map((email) => {
      this.formGroup.controls.list.push(
        new FormControl(email, {
          nonNullable: true,
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        })
      );
    });
    this.subscribeOnFormValueChange();
  }

  subscribeOnFormValueChange() {
    //FOR RESET FORM FROM PARENT
    const parentControl = this.parentFormGroup.controls[this.controlName];
    parentControl.valueChanges.subscribe((value: any) => {
      if (value === null) {
        this.formGroup.controls.list.clear();
      }
    });

    this.formGroup.controls.list.valueChanges.subscribe((value) => {
      this.parentFormGroup.controls[this.controlName].setValue(
        value.map((email) => email.trim().toLocaleLowerCase())
      );
    });
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  insertItem() {
    this.formGroup.controls.list.push(
      new FormControl(this.formGroup.controls.newItem.value, {
        nonNullable: true,
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      })
    );

    this.formGroup.controls.newItem.setValue('');
  }

  removeItem(index: number) {
    this.formGroup.controls.list.removeAt(index);
  }

  keypress(event: KeyboardEvent) {
    if (!this.formGroup.controls['newItem'].valid) return;

    if (event.key === 'Enter') this.insertItem();
  }

  async uploadCSV(event: any) {
    let csvFile = event.target?.files[0];
    event.target.value = '';
    if (!csvFile) return;

    await csvFile.text().then((csvText: string) => {
      this.papa.parse(csvText, {
        complete: (result) => {
          let csvData: Array<Array<string>> = result.data;
          csvData.map((email) => {
            if (this.formGroup.controls.list.value.includes(email[0])) {
              alert(`E-mail duplicado: ${email[0]}`);
            } else {
              this.formGroup.controls.list.push(
                new FormControl(email[0], {
                  nonNullable: true,
                  validators: [Validators.required, Validators.email],
                  updateOn: 'blur',
                })
              );
            }
          });
        },
      });
    });
  }
}
