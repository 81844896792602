import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { EnrollmentService } from 'src/app/enrollment/services/enrollment/enrollment.service';
import { EnrollmentQuery } from 'src/generated/graphql';

@Component({
  selector: 'app-shared-modal-rate-experience',
  templateUrl: './shared-modal-rate-experience.component.html',
  styleUrls: ['./shared-modal-rate-experience.component.scss'],
})
export class SharedModalRateExperienceComponent implements OnInit {
  @Input() id: string;
  @Input() enrollment: EnrollmentQuery['enrollment'];
  formGroup: FormGroup;

  constructor(
    public translations: TranslationsLayoutService,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private enrollmentService: EnrollmentService
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      rating: [
        { value: '', disabled: false },
        Validators.compose([Validators.required]),
      ],
      description: [{ value: '', disabled: false }],
    });
  }

  async sendRating() {
    await this.enrollmentService.update({
      id: this.enrollment.id,
      rating: this.formGroup.controls.rating.value,
      ratingComment: this.formGroup.controls.description.value
    });

    this.closeModal({ sended: true });
  }

  closeModal(data: any = undefined) {
    this.modalController.dismiss(data, undefined, this.id);
  }
}
