import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'shared-form-date-input',
  templateUrl: './shared-form-date-input.component.html',
  styleUrls: ['./shared-form-date-input.component.scss'],
})
export class SharedFormDateInputComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() placeholder = '';
  @Input() helpText: string;
  @Input() isTime = false;
  @Input() defaultDate?: Date;

  constructor() {}

  ngOnInit() {
    // if (this.defaultDate) {
    //   if (this.isTime) {
    //     this.parentFormGroup.controls[this.controlName].setValue(
    //       this.defaultDate.toLocaleTimeString()
    //     );
    //   } else {
    //     this.parentFormGroup.controls[this.controlName].setValue(
    //       this.defaultDate.toLocaleDateString()
    //     );
    //   }
    // }

    const control = this.parentFormGroup.controls[this.controlName];
    if (control.value) {
      const date = new Date(control.value).toISOString().split('T')[0];
      control.setValue(date, {
        emitEvent: false,
      });
    }

    this.subscribeOnFormValueChange();
  }

  subscribeOnFormValueChange() {
    const control = this.parentFormGroup.controls[this.controlName];
    control.valueChanges.subscribe((data: string) => {
      console.log('data', data);
      if (typeof data === 'undefined' || data === '') return;
      const date = new Date(data).toISOString().split('T')[0];
      control.setValue(date, {
        emitEvent: false,
      });
    });
  }

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
