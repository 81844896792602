import { Injectable } from '@angular/core';
import {
  ApolloCache,
  ApolloClientOptions,
  ApolloLink,
  DefaultOptions,
  FetchResult,
  InMemoryCache,
  Operation,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from 'apollo-angular/http';
import { extractFiles } from 'extract-files';
import { DocumentNode, OperationDefinitionNode } from 'graphql';
import { environment } from 'src/environments/environment';
import {
  CreateEnrollmentDocument,
  CreateExperienceDocument,
  CreateHostUserDocument,
  CreateStudentUserForSubscriptionDocument,
  CreateStudentUserPortugalDocument,
  LoginUserDocument,
  RemoveEnrollmentDocument,
  RemoveExperienceDocument,
  RemoveHostDocument,
  RemoveInstitutionDocument,
  RemoveScheduleDocument,
  RemoveStudentDocument,
  UpdateHostUserDocument,
  UpdateNotificationDocument,
  UpdateScheduleDocument,
  UpdateStudentUserDocument,
} from 'src/generated/graphql';
import { DiOptionsAlone } from 'src/types/di/interfaces/di-query';
import { LoadingLayoutService } from '../../services/loading/loading-layout.service';
import { ToastService } from '../../services/toast/toast.service';

const uri = environment.url;
@Injectable({
  providedIn: 'root',
})
export class ApolloOptions implements ApolloClientOptions<any> {
  link?: ApolloLink | undefined;

  cache: ApolloCache<any>;
  defaultOptions?: DefaultOptions | undefined;

  loadings: Array<Promise<HTMLIonLoadingElement>> | undefined = [];
  loadingLayersCount: number = 0;

  constructor(
    private httpLink: HttpLink,
    private toastService: ToastService,
    private loadingService: LoadingLayoutService
  ) {
    const basic = setContext((operation, context) => ({
      headers: {
        Accept: 'charset=utf-8',
      },
    }));

    const auth = setContext(async (operation, context) => {
      const token = localStorage.getItem(environment.tokenName);

      if (typeof token == 'undefined' || token == null || token == 'undefined')
        return {};

      return {
        headers: { Authorization: `Bearer ${token}` },
      };
    });

    const middleware = this.createMiddleware();
    const errorMiddleware = this.createErrorMiddleware();

    const namedLink = new ApolloLink((operation, forward) => {
      operation.setContext(() => ({
        uri: `${uri}?${operation.operationName}`,
      }));

      return forward ? forward(operation) : null;
    });

    this.link = ApolloLink.from([
      basic,
      namedLink,
      auth,
      middleware,
      errorMiddleware,
      this.httpLink.create({ uri, extractFiles }),
    ]);

    this.cache = new InMemoryCache();

    this.defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
  }

  createErrorMiddleware() {
    const link = onError(({ graphQLErrors, networkError }) => {
      if (this.loadings && this.loadings.length > 0) {
        for (const loading of this.loadings) {
          loading.then((loading: HTMLIonLoadingElement) => {
            loading.dismiss();
          });
        }
        this.loadings = [];
      }
      // if (graphQLErrors)
      //   graphQLErrors.map(({ message, locations, path }) =>
      //     console.log(
      //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      //     )
      //   );

      // if (networkError) console.log(`[Network error]: ${networkError}`);
    });
    return link;
  }

  createMiddleware() {
    return new ApolloLink((operation, forward) => {
      const context: DiOptionsAlone['context'] = operation.getContext();

      let loading: Promise<HTMLIonLoadingElement>;
      if (context.isToLoad) {
        loading = this.loadingService.create();
        this.loadings?.push(loading);
      }

      const responseData = forward(operation).map((response) => {
        if (context.isToLoad) {
          loading.then((loading: HTMLIonLoadingElement) => {
            loading.dismiss();
            const loadingIndex = this.loadings?.findIndex(async (_loading) => {
              return (await _loading) == loading;
            });

            if (typeof loadingIndex !== 'undefined')
              this.loadings?.splice(loadingIndex, 1);
          });
        }

        const hasErrors = (response?.errors?.length ?? 0) > 0;

        if (!hasErrors) {
          this.handleSuccess(operation, context);
        } else if (hasErrors) {
          this.handleErrors(response, context);
        }

        return response;
      });

      return responseData;
    });
  }

  async handleSuccess(
    operation: Operation,
    context: DiOptionsAlone['context']
  ) {
    if (
      this.getOperationDefinitionName(operation.query)?.operation !== 'mutation'
    )
      return;

    const mappeds = new Map<
      string | undefined,
      string | (() => void) | undefined | false
    >([
      [this.getOperationDefinitionName(LoginUserDocument)?.name?.value, false],
      [
        this.getOperationDefinitionName(CreateStudentUserPortugalDocument)?.name
          ?.value,
        'Cadastro efetuado com sucesso! Acesse seu email para ativar sua conta.',
      ],
      [
        this.getOperationDefinitionName(
          CreateStudentUserForSubscriptionDocument
        )?.name?.value,
        'Cadastro efetuado com sucesso! Acesse seu email para ativar sua conta.',
      ],
      [
        this.getOperationDefinitionName(CreateHostUserDocument)?.name?.value,
        'Cadastro efetuado com sucesso! Acesse seu email para ativar sua conta e também espere a aprovação do administrador.',
      ],
      [
        this.getOperationDefinitionName(CreateEnrollmentDocument)?.name?.value,
        'Inscrição realizada com sucesso!',
      ],
      [
        this.getOperationDefinitionName(RemoveEnrollmentDocument)?.name?.value,
        'Inscrição cancelada com sucesso!',
      ],

      [
        this.getOperationDefinitionName(UpdateScheduleDocument)?.name?.value,
        'Agendamento atualizado com sucesso!',
      ],
      [
        this.getOperationDefinitionName(CreateExperienceDocument)?.name?.value,
        'Experiência criada',
      ],
      [
        this.getOperationDefinitionName(RemoveHostDocument)?.name?.value,
        'Anfitrião deletado com sucesso!',
      ],
      [
        this.getOperationDefinitionName(RemoveInstitutionDocument)?.name?.value,
        'Instituição deletada com sucesso!',
      ],
      [
        this.getOperationDefinitionName(RemoveScheduleDocument)?.name?.value,
        'Agendamento deletado com sucesso',
      ],
      [
        this.getOperationDefinitionName(UpdateScheduleDocument)?.name?.value,
        'Agendamento realizado com sucesso!',
      ],
      [
        this.getOperationDefinitionName(UpdateNotificationDocument)?.name
          ?.value,
        'Notificação atualizada com sucessso!',
      ],
      [
        this.getOperationDefinitionName(RemoveExperienceDocument)?.name?.value,
        'Experiência deletada com sucesso!',
      ],
      [
        this.getOperationDefinitionName(RemoveStudentDocument)?.name?.value,
        'Deletado com sucesso!',
      ],
      [
        this.getOperationDefinitionName(UpdateHostUserDocument)?.name?.value,
        'Dados atualizados com Sucesso!',
      ],
      [
        this.getOperationDefinitionName(UpdateStudentUserDocument)?.name?.value,
        'Dados atualizados com Sucesso!',
      ],
    ]);

    const mapped = mappeds.get(
      this.getOperationDefinitionName(operation.query)?.name?.value
    );

    if (typeof mapped === 'string')
      this.showMessage(mapped, 'success', context);
    else if (mapped !== false)
      await this.showMessage('Sucesso!  ', 'success', context);
  }
  async handleErrors(
    response: FetchResult<
      Record<string, any>,
      Record<string, any>,
      Record<string, any>
    >,
    context: DiOptionsAlone['context']
  ) {
    if (response?.errors?.[0].message === 'Unauthorized') {
      localStorage.removeItem(environment.tokenName);
      this.toastService.error('Erro na sua sessão!');
      setTimeout(() => {
        window.location.href = 'auth/login';
      }, 1000);
      // return response;
    }

    if (response?.errors?.[0].message) {
      if (context.isToHideMessage === undefined) {
        context.isToHideMessage = false;
      }
      this.showMessage(response?.errors?.[0].message, 'error', context);
    }

    throw Error('Di Apollo Request Error');
  }

  getOperationDefinitionName(
    query: DocumentNode
  ): OperationDefinitionNode | undefined {
    if (query.definitions[0].kind === 'OperationDefinition') {
      return query.definitions[0];
    }
    return undefined;
  }

  async showMessage(
    message: string,
    type: 'success' | 'error',
    context: DiOptionsAlone['context']
  ) {
    if (context.isToHideMessage === true) return;
    await this.toastService[type](message);
  }
}
