import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';
import { UserService } from 'src/app/user/services/user/user.service';
import { environment } from 'src/environments/environment';
import { UserRole } from 'src/generated/graphql';

@Component({
  selector: 'user-pop-over-profile',
  templateUrl: './user-pop-over-profile.component.html',
  styleUrls: ['./user-pop-over-profile.component.scss'],
})
export class UserPopOverProfileComponent implements OnInit {
  userRole = UserRole;

  constructor(
    private router: Router,
    public translations: TranslationsLayoutService,
    private popOverController: PopoverController,
    public userService: UserService
  ) {}

  ngOnInit() {}

  navigate(route: string) {
    this.popOverController.dismiss();
    this.router.navigate([route]);
  }

  logout() {
    this.popOverController.dismiss();

    const user = this.userService.getUser();
    const isIntegrationUser = this.userService.checkIsIntegrationUser();

    localStorage.setItem(environment.tokenName, '');
    this.userService.updateCurrentUser(null);

    const url = user?.currentUser.studentUser?.institution.url;

    if (typeof url === 'string' && isIntegrationUser) {
      window.location.href = `//${url}`;
    } else this.router.navigate(['/auth/login']);
  }
}
