import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  ImageTransform,
} from 'ngx-image-cropper';
import { TranslationsLayoutService } from 'src/app/core/services/translations/translations-layout.service';

@Component({
  selector: 'user-modal-croppie-avatar',
  templateUrl: './user-modal-croppie-avatar.component.html',
  styleUrls: ['./user-modal-croppie-avatar.component.scss'],
})
export class UserModalCroppieAvatarComponent implements OnInit {
  @Input() id: string;
  @Input() imageEvent: Event;
  @ViewChild('imageCropper') imageCropper: ImageCropperComponent;
  aspectRatio = 75 / 55;
  width = 750;
  height = 550;
  round = false;
  quality = 100;
  croppedImage: string = '';
  transform: ImageTransform = {};
  loading = false;
  imageCropperLoad = false;
  canvasRotation = 0;

  constructor(
    private modalController: ModalController,
    public translations: TranslationsLayoutService
  ) {}

  ngOnInit() {}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64 || '';
    this.loading = false;
  }

  imageLoaded() {}

  cropperReady() {
    this.imageCropperLoad = true;
  }
  loadImageFailed() {
    // show message
  }

  rotateLeft() {
    if (this.loading) return;

    this.loading = true;
    setTimeout(() => {
      this.canvasRotation--;
      this.flipAfterRotate();
    });
  }

  rotateRight() {
    if (this.loading) return;

    this.loading = true;
    setTimeout(() => {
      this.canvasRotation++;
      this.flipAfterRotate();
    });
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  saveImage() {
    this.modalController.dismiss(this.croppedImage, undefined, this.id);
  }

  closeModal() {
    this.modalController.dismiss(null, undefined, this.id);
  }
}
