import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  CountNotificationsGQL,
  FilterNotificationInput,
  FilterOperation,
  InputMaybe,
  ListNotificationsGQL,
  UpdateNotificationGQL,
  UpdateNotificationInput,
} from 'src/generated/graphql';

@Injectable()
export class NotificationService {
  notificationsCount: number = 0;

  constructor(
    private listNotificationsGQL: ListNotificationsGQL,
    private updateNotificationGQL: UpdateNotificationGQL,
    private countNotificationsGQL: CountNotificationsGQL
  ) {}

  async list(input?: InputMaybe<FilterNotificationInput> | undefined) {
    const result = await firstValueFrom(
      this.listNotificationsGQL.fetch(
        { input },
        { context: { isToLoad: true } }
      )
    );
    return result.data.notifications;
  }

  async update(input: UpdateNotificationInput) {
    const result = await firstValueFrom(
      this.updateNotificationGQL.mutate(
        { input },
        { context: { isToLoad: true, isToHideMessage: true } }
      )
    );
    return result.data?.updateNotification;
  }

  async count(input?: InputMaybe<FilterNotificationInput> | undefined) {
    const result = await firstValueFrom(
      this.countNotificationsGQL.fetch(
        { input },
        { context: { isToLoad: false } }
      )
    );
    return result.data.notifications;
  }

  async updateViewCount() {
    const result = await this.count({
      readed: { value: [false], operation: FilterOperation.Equals },
    });
    this.notificationsCount = result.length;
    return this.notificationsCount;
  }
}
