import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "shared-form-toggle-list",
  templateUrl: "./shared-form-toggle-list.component.html",
  styleUrls: ["./shared-form-toggle-list.component.scss"],
})
export class SharedFormToggleListComponent implements OnInit {
  @Input() title: string;
  @Input() helpText: string;
  @Input() parentFormGroup: FormGroup;
  @Input() items: Array<string>;

  constructor() {}

  ngOnInit() {}
}
