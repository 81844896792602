import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";

@Component({
  selector: "shared-form-cpf-input",
  templateUrl: "./shared-form-cpf-input.component.html",
  styleUrls: ["./shared-form-cpf-input.component.scss"],
})
export class SharedFormCpfInputComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() title: string;
  @Input() placeholder: string = "";
  @Input() helpText: string;

  constructor() {}

  ngOnInit() {}

  isControlRequired(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }
}
