import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './core/guards/authenticated.guard';
import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'experience',
    loadChildren: () =>
      import('./experience/experience.module').then((m) => m.ExperienceModule),
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'jitsi',
    loadChildren: () =>
      import('./jitsi/jitsi.module').then((m) => m.JitsiModule),
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'host',
    loadChildren: () => import('./host/host.module').then((m) => m.HostModule),
  },

  {
    path: 'institution',
    loadChildren: () =>
      import('./institution/institution.module').then(
        (m) => m.InstitutionModule
      ),
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
  },

  {
    path: 'schedule',
    loadChildren: () =>
      import('./schedule/schedule.module').then((m) => m.ScheduleModule),
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
  },

  {
    path: 'support',
    loadChildren: () =>
      import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'student',
    loadChildren: () =>
      import('./student/student.module').then((m) => m.StudentModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [AuthenticatedGuard, RoleGuard],
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: () =>
      import('./politica-de-privacidade/politica-de-privacidade.module').then(
        (m) => m.PoliticaDePrivacidadePageModule
      ),
  },
  {
    path: 'termos-de-uso',
    loadChildren: () =>
      import('./termos-de-uso/termos-de-uso.module').then(
        (m) => m.TermosDeUsoPageModule
      ),
  },
  {
    path: 'teste-vocacional',
    loadChildren: () =>
      import('./vocational-test/vocational-test.module').then(
        (m) => m.VocationalTestModule
      ),
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
  },
  {
    path: 'seren-deep',
    loadChildren: () =>
      import('./seren-deep/seren-deep.module').then(
        (m) => m.SerenDeepModule
      ),
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
